import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const postReviewFunc = createAsyncThunk(
    "reviews/post", 
    async ({url , data}) => {
    try {
        const response = await axios.post(url,data);
        // checkErrorsFunctions(response , response.data.errors);
        return response.data;
    } catch (error) {
        checkErrors(error.response , error.message);
        return error;
    }
});

const postReviewSlicer = createSlice({
  name: "reviews/post",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postReviewFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(postReviewFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(postReviewFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default postReviewSlicer.reducer;
