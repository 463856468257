import { useEffect } from "react";
import { logout } from "../services/logoutAction";
import { toast } from "react-toastify";
function LogOut() {
  const user = JSON.parse(window.localStorage.getItem("userData"))
  const expire_date = window.localStorage.getItem("expire_date");
    // const expire_date = window.localStorage.getItem("expiry_date")
    
    useEffect(() => {
        const checkerInterval = setInterval(() => {
          const dateNow = Date.now();
          const expirationTime = new Date(expire_date).getTime();
          const timeDifference = expirationTime - dateNow;
        if(timeDifference > 0 && timeDifference <= 60000) {
          toast.warning("جلسه تسجيل الدخول تنتهي بعد دقيقه!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
          if (dateNow >= expirationTime) {
            logout();
          }
        }, 60 * 1000); // Check every 1 minute
        return () => clearInterval(checkerInterval);
      }, [expire_date]);
    return (
        <>
        </>
    )
}
export default LogOut;