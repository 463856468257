import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/checkResponseError";

export const getExpireVideoFunc = createAsyncThunk("lessons/video/get-expire", async (api) => {
  try {
        const response = await axios.post(api, {});
        return response.data;
    } catch (error) {
        checkErrors(error.response , error.message)
        console.log(error)
    }
});

const getExpireVideoSlicer = createSlice({
  name: "lessons/video/get-expire",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getExpireVideoFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getExpireVideoFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getExpireVideoFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default getExpireVideoSlicer.reducer;
