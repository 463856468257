import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getSingleCategoryFunc } from "../../../../store/slices/categories/GetSingleCategorySlicer";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import failedImg from "../../../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
import SubCategoryCard from "./category-card/SubCategoryCard";
import { useNavigate } from "react-router-dom";

export default function SubCategoriesSection({urlId}) {
    const {t} = useTranslation();
    // const {loading:showCategoryLoading , data:categoryData} = useSelector(
    //     state => state.categoriesSlicer
    //   )
    const {loading:showCategoryLoading , data:categoryData} = useSelector(
      state => state.getSingleCategorySlicer
    )
      const navigate = useNavigate();
      const dispatch = useDispatch();
      const [subCategoriesList , setSubCategoriesList] = useState([])
      useEffect(() => {
        urlId && dispatch(getSingleCategoryFunc(urlId))
      },[urlId])
      
      useEffect(() => {
        if(categoryData) {
          setSubCategoriesList(categoryData?.data?.category?.all_children)
          console.log(categoryData)
        }
      },[categoryData])
      const showCategory = (id) => {
        // dispatch(getSingleCategoryFunc(urlId))
        navigate(`/courses?category=${id}`)
      }
    return (
        <>
        {
          subCategoriesList?.length >= 1
          ?
          <div className="section relative py-[100px]">
              <div className="section-title text-center">
                  {/* <span className="section-title__tagline text-[#00a4e5]">{t("Browse Sub Categories")}</span> */}
                  <h2 className="section-title__title">{t("Explore Our Sub Categories")}</h2>
              </div>
              <div className="container">
                  <div className="row gap-3 justify-center">
                      {
                          showCategoryLoading
                          ?
                          <>
                              <div className="row d-flex justify-content-center gap-2">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                              </div>
                          </>
                          :
                          categoryData
                          ?
                          (
                            subCategoriesList?.length >= 1
                            ?
                            (
                              subCategoriesList.map((cat , index) => (
                                <SubCategoryCard
                                  key={index}
                                  id={cat.id}
                                  name={cat.name}
                                  image={cat.image_url}
                                  subCategoriesCount={cat.all_children?.length}
                                  showCategory = {() => showCategory(cat.id)}
                                />
                              ))
                            )
                            :
                            <div className="relative w-full flex flex-col justify-center items-center">
                              <img
                                loading="lazy"
                                src={failedImg}
                                alt="failed img"
                                className="w-[150px] mb-3"
                              />
                              <p className="">
                                {t("No Sub Categories ")}!
                              </p>
                            </div>
                          )
                          :
                          <div className="relative w-full  flex flex-col justify-center items-center">
                            <img
                              loading="lazy"
                              src={failedImg}
                              alt="failed img"
                              className="w-[150px] mb-3"
                            />
                            <p className="">
                              {t("Faild Load Sub Categories ")}!
                            </p>
                          </div>

                      }
                  </div>
              </div>
          </div>
          :
          ""
        }
        </>
    )
}