export default function CardExamUpload({ id }) {
  return (
    <>
      <div className="tutor-card-exam">
        <div className="card-exam-title">
          <span className="question-number">
            <strong>السوال {id} من 8</strong>
          </span>
          <span className="question-degree">
            <em>
              الدرجة : <strong> 1</strong>
            </em>
          </span>
        </div>
        <div className="card-exam-body">
          <div className="card-exam-body-questions">
            <p id={`ques-${id}`}>هذا هو السوال الاول ؟</p>
          </div>
          <div className="card-exam-body-answers">
            <form
              className="comment-one__form contact-form-validated"
              noValidate="novalidate"
            >
              <div className="col-xl-12 col-lg-12">
                <div className="comment-form__input-box">
                  <div className="mb-3">
                    <label htmlFor="formFile" className="form-label">
                   اكتب الاجابة في ورقة وارفع صورة واضحة
                    </label>
                    <input className="form-control" type="file" id="formFile" />
                  </div>
                </div>
              </div>
             
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
