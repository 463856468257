import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const showSingleLessonFunc = createAsyncThunk("lessons/show/single", async (url) => {
  try {
      const response = await axios.get(url);
      checkErrorsFunctions(response , response.data.errors);
      return response.data
    } catch (error) {
      checkErrors(error.response , error.message)
      return error
    }
});

const showSingleLessonSlicer = createSlice({
  name: "lessons/show/single",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showSingleLessonFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(showSingleLessonFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(showSingleLessonFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default showSingleLessonSlicer.reducer;
