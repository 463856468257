import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const postVeryfyEmailFunc = createAsyncThunk(
  "postverify/postVeryfyEmailFunc",
  async ({api , data}) => {
    try {
      const response = await axios.post(api, data);
      // checkErrorsFunctions(response , response.data.errors);
      return response.data;
    } catch (error) {
      checkErrors(error.response , error.message)
      return error;
    }
  }
);

const postVeryfyEmailSlicer = createSlice({
  name: "postverify",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postVeryfyEmailFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postVeryfyEmailFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(postVeryfyEmailFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

export default postVeryfyEmailSlicer.reducer;
