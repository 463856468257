export const logout  = ()=>{
    localStorage.removeItem("userdata");
    localStorage.removeItem("expire_date");
    localStorage.removeItem("token");
    localStorage.removeItem("studentInvoices");
    window.location.href = "/login";
}
export const isLoginAction = ()=>{
    const userdata = JSON.parse(localStorage.getItem("userdata"));
    return userdata ? true : false;
}