import { useTranslation } from "react-i18next"

export default function SubCategoryCard({id , image , name , subCategoriesCount , showCategory}) {
    const {t} = useTranslation();
    return (
        <>
            <div data-aos="fade-up" className="aos relative col-sm-12 col-md-3 rounded-lg flex flex-col  min-h-[200px]  cursor-pointer py-[20px] group category-card overflow-hidden"
            onClick={showCategory}
            >
                {/* <div className="image-div relative w-[100px] h-[100px] rounded-[50%] overflow-hidden bg-[#13989745] group-hover:bg-[#13989796] transition-all duration-500 my-[20px]"> */}
                    <img src={image ? image : "sc"} alt="cat icon" className="absolute top-0 left-0 w-full h-full" />
                {/* </div> */}
                <div className="about-cat flex flex-col justify-center items-center absolute bottom-0 z-10">
                    <p className="mb-2 font-bold text-white">{name}</p>
                    {/* <p className="mb-2">( {subCategoriesCount} ) {t("Sub Categories")}</p> */}
                </div>
            </div>
        </>
    )
}