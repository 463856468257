import { useTranslation } from "react-i18next";
import "./contact.css";
function OverLayLoading({loadingText , loading}) {
    const {t} = useTranslation();
    return (
        <>
            <div className={`loading-data bg-[#00000096] z-[99999999999] fixed top-0 left-0 w-full h-[100vh] flex justify-center transition-all deuration-500 items-center flex-col ${loading ? "active" : ""}`}>
                <div className="loader w-[70px] h-[110px] relative rounded-[50%]  before:absolute before:top-[5px] before:left-[5px] before:right-[5px] before:bottom-[5px] before:rounded-[50%] after:absolute after:top-[15px] after:left-[15px] after:right-[15px] after:bottom-[15px] after:rounded-[50%]"></div>
                <h2 className="text-white mt-[50px] tracking-[10px] text-xl">{t(loadingText)}</h2>
            </div>
        </>
    )
}

export default OverLayLoading;