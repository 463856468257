import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getStudentCoursesFunc = createAsyncThunk(
  "student/courses",
  async () => {
    try {
      // return await axios.get(
      //     `${process.env.REACT_APP_PUBLIC_API}/api/courses/${course_id}/lessons`,
      //     { withCredentials: true }
      //   )
      //   .then((data) => {
        //     return data.data;
        //   })
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/student/my-courses`)
        checkErrorsFunctions(response , response.data.errors);
        console.log(response.data)
        return response.data.data.courses;
      
    } catch (error) {
      return error;
    }
  }
);

const getStudentCoursesSlicers = createSlice({
  name: "getStudentCourses",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentCoursesFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getStudentCoursesFunc.fulfilled, (state, action) => {
      state.loading = false;

      state.data = action.payload;
    });
    builder.addCase(getStudentCoursesFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getStudentCoursesSlicers.reducer;
