import Partners from "../../component/ui/Partners";
import SwiperSlider from "./component/SwiperSlider";
import TeachersSection from "./component/teachers/TeachersSection";
import CategoriesSection from "./component/categories/CategoriesSection";
import HowItIsWork from "./component/how-it-is-work/HowItIsWork";

export default function Home() {

  return (
      <>
          <SwiperSlider />
          {/* <Features /> */}
          {/* <About /> */}
          {/* <BestSellingSection/> */}
          {/* <Registeration /> */}
          <CategoriesSection/>
          <TeachersSection/>
          <Partners />
          {/* <HowItIsWork/> */}
          {/* <Whyus /> */}
      </>
  )
}
