import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import Swal from "sweetalert2";

export const downloadLessonFileFunc = createAsyncThunk(
    "lessons/download", 
    async ({url , postCompletingLesson}) => {
      try {
        axios.get(url , {
          withCredentials : true,
          responseType: 'blob', // Important: Set response type to blob
      })
      .then(response => {
        console.log(response)
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          if(response.data && response.data.type) {
            if (response.data.type === "application/pdf") {
              link.setAttribute('download', "Lesson.pdf");
            } else {
              link.setAttribute('download', "Lesson.zip");
            }
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            postCompletingLesson();
          }
      })
      }
      catch(error) {
        Swal.fire({
          title: "عذرا!",
          text: error,
          icon: "error"
        })
      }
});

const downloadLessonFileSlicer = createSlice({
  name: "download",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(downloadLessonFileFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(downloadLessonFileFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(downloadLessonFileFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default downloadLessonFileSlicer.reducer;
