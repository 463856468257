import "./styles/style.css";
import Navbar from "../../component/Nav/Navbar";
import Btn from "../../component/ui/Btn";
import Wrappers from "../../component/ui/Wrappers";
import Footer from "../../component/ui/Footer";
import MobileNavbar from "../../component/Nav/MobileNavbar";
import SearchBar from "../../component/ui/SearchBar";
import ScrollUp from "../../component/ui/ScrollUp";
import LoginFrom from "./component/LoginFrom";
import Offcanvan from "../../component/Nav/Offcanvan";
import { useEffect } from "react";
export default function Login() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <LoginFrom />
    </>
  );
}
