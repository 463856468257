import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ShowingLessonSchadulesTable() {
  const {t} = useTranslation();
  const {data:targetLessonData} = useSelector(
      state => state.setLessonWhichWillCompletItSlicer
  )
  return (
    <TableContainer component={Paper} className='mx-0 px-0'>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right">{t("Name")}</StyledTableCell>
            <StyledTableCell align="right">{t("From")}</StyledTableCell>
            <StyledTableCell align="right">{t("To")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          targetLessonData?.lesson_schadules?.map((item) => {
            const {title , pivot} = item;
            const {start_date , end_date} = pivot;
            return (
                <StyledTableRow key={item.name}>
                <StyledTableCell component="th" scope="row" align="right">
                  {title}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="right">
                  {start_date}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {end_date}
                </StyledTableCell>
              </StyledTableRow>
            )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
