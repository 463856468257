import { useTranslation } from "react-i18next"

export default function CategoryCard({id , image , name , subCategoriesCount , showCategory}) {
    const {t} = useTranslation();
    return (
        <>
            <div className="relative col-sm-12 col-md-3 rounded-lg overflow-hidden flex flex-col justify-center items-center min-h-[200px] cursor-pointer group category-card"
            onClick={showCategory}
            >
                {/* <div className="image-div relative w-[100px] h-[100px] rounded-[50%] overflow-hidden bg-[#13989745] group-hover:bg-[#13989796] transition-all duration-500 my-[20px]">
                    <img src={image ? image : "sc"} alt="cat icon" className="absolute top-0 left-0 w-full h-full" />
                </div>
                <div className="about-cat relative flex flex-col justify-center items-center">
                    <p className="mb-2 font-bold text-slate-800">{name}</p>
                </div> */}
                {/* <div className="image-div relative w-[100px] h-[100px] rounded-[50%] overflow-hidden bg-[#13989745] group-hover:bg-[#13989796] transition-all duration-500 my-[20px]"> */}
                    <img src={image ? image : "sc"} alt="cat icon" className="absolute top-0 left-0 w-full h-full" />
                {/* </div> */}
                <span className="absolute top-[50%] left-[50%] w-0 h-0 bg-slate-800 opacity-0 transition-all duration-500 group-hover:w-full group-hover:top-0 group-hover:left-0 group-hover:h-full group-hover:opacity-20"></span>
                <div className="about-cat w-full absolute bottom-0 flex flex-col justify-start items-start p-2 z-10">
                    <p className="mb-2 font-bold text-white transition-all duration-500">{name}</p>
                </div>
            </div>
        </>
    )
}