import { useState } from "react";

export default function CardExamTF({sendAnswer, numofcoustions, qunamber, question }) {
  console.log("tr", question);

  const [answer, setAnswer] = useState("");

  return (
    <>
      <form className="tutor-card-exam" onSubmit={()=>{
        sendAnswer({
          id:question.id,
          answer
        });
      }}>
        <div className="card-exam-title">
          <span className="question-number">
            <strong>
              السوال {qunamber + 1} من {numofcoustions}
            </strong>
          </span>
          <span className="question-degree">
            <em>
              الدرجة : <strong> {question.grade}</strong>
            </em>
          </span>
        </div>
        <div className="card-exam-body">
          <div className="card-exam-body-questions">
            <p id={`ques-${question.id}`}>{question.title}</p>
          </div>
          <div className="card-exam-body-answers">
            <form
              className="comment-one__form contact-form-validated"
              noValidate="novalidate"
            >
              <div className="col-xl-12 col-lg-12">
                <div className="comment-form__input-box">
                  <div className="form-group form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={`ques-${question.id}-exampleCheck1`}
                      name={`ques-${question.id}`}
                      onChange={(e) => {
                        setAnswer("1");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`ques-${question.id}-exampleCheck1`}
                    >
                      اجابة صحيحة
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12">
                <div className="comment-form__input-box">
                  <div className="form-group form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={`ques-${question.id}-exampleCheck2`}
                      name={`ques-${question.id}`}
                      onChange={(e) => {
                        setAnswer("2");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`ques-${question.id}-exampleCheck2`}
                    >
                      اجابة خاطئة
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </form>
    </>
  );
}
