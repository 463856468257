import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const changePasswordFunc = createAsyncThunk(
  "subscriptionsCourses/changePasswordFunc",
  async ({api , data}) => {
    try {
      const response = await axios.put(api, data);
      // checkErrorsFunctions(response , response.data.errors);
    //   return response.data.data.lessons;
    } catch (error) {
      checkErrors(error.response , error.message)
      return error;
    }
  }
);

const changePasswordSlicer = createSlice({
  name: "subscriptionsCourses",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(changePasswordFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePasswordFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(changePasswordFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
  },
});

export default changePasswordSlicer.reducer;
