import {configureStore} from "@reduxjs/toolkit"
import userReduser from "./slices/userSlicer"
import coursesReducer from "./slices/coursesSlicer";
import contactSlice from "./slices/contact/contactSlicer";
import categoriesSlicer from "./slices/categories/CategoriesSlicer";
import getSingleCategorySlicer from "./slices/categories/GetSingleCategorySlicer";
import coursesSlicer from "./slices/courses/coursesSlicer";
import singleCourseSlicer from "./slices/courses/singleCourseSlicer";
import profileSlicer from "./slices/users/profileSlicer";
import lessonsSlicers from "./slices/courses/lessonsSlicers";
import shwoLessonSlicer from "./slices/lessons/showLessonSlicer";
import downloadLessonFileSlicer from "./slices/lessons/DownloadLessonFileSlicer";
import showQuizesSlicers from "./slices/quizes/showQuizesSlicers";
import subscriptionsCoursesSlicer from "./slices/users/subscriptionsCoursesSlicer";
import postStudentAnswersSlicer from "./slices/quizes/PostStudentAnswersSlicer";
import getMainQuizeProgressSlicer from "./slices/quizes/GetMainQuizeProgress";
import getTeachersSlicer from "./slices/teachers/GetTeachersSlicer";
import getSingleTeacherSlicer from "./slices/teachers/GetSingleTeacherSlicer";
import changePasswordSlicer from "./slices/users/ChangePasswordSlicer";
import editUserImageSlicer from "./slices/users/ChangeImageSlicer"
import postMessageSlicer from "./slices/contact/PostMessageSlicer"
import getRankedStudentsSlicer from "./slices/students-actions/GetRankedStudentsSlicer"
import getLevelsSlicer from "./slices/levels/LevelsSlicer"
import GetAllCoursesSlicer from "./slices/courses/coursesSlicer"
import subscribeRequestSlicer from "./slices/courses/SubscribeRequestSlicer"
import readAllNotficationsSlicer from "./slices/notfications-slicers/ReadAllNotficationsSlicer";
import getNotficationSlicer from "./slices/notfications-slicers/GetNotficationsSlicer";
import getAttemptSlicer from "./slices/attempt-actions/GetAttemptSlicer";
import getUiSettingsSlicer from "./slices/ui-settings-actions/GetUiSettingsSlicer";
import postCompletingLessonSlicer from "./slices/lessons/PostCompletingLessonSlicer";

// copone slicers
import applayCouponeSlicer from "./slices/copons-actions/ApplayCouponSlicer";
import checkoutSlicer from "./slices/checkout-actions/CheckoutSlicer";
import activeCourseSlicer from "./slices/courses/activation-actions/ActiveCourseSlicer";

import registerSlicer from "./slices/register-action/RegisterSlicer";
import resetPassSlicer from "./slices/users/ResetPasswordSlicer";
import forgotPassSlicer from "./slices/users/ForgotPassSlicer";
import postVeryfyEmailSlicer from "./slices/users/PostVerifyEmailSlicer";
import getVerifyEmailSlicer from "./slices/users/VerifyEmailSlicer";
import postVerifingCodeSlicer from "./slices/users/PostVerifingCodeSlicer";


import getAllAtemptsSlicer from "./slices/attempt-actions/GetFreeAttempsSlicer";
import getStudentCoursesSlicer from "./slices/courses/GetStudentCoursesSlicer";
import getBestSellingCoursesSlicer from "./slices/courses/GetBestSellingCoursesSlicer";

//showLesson
import setLessonWhichWillCompletItSlicer from "./slices/lessons/SetLessonWhichWillCompletItSlicer";
import showSingleLessonSlicer from "./slices/lessons/ShowSingleLessonSlicer";
import showStreamLessonSlicer from "./slices/lessons/ShowStreamLessonSlicer";
import showLessonQuizeSlicer from "./slices/lessons/ShowLessonQuizeSlicer";
import getExpireVideoSlicer from "./slices/lessons/GetExpireVideoSlicer";
import getExpirePdfSlicer from "./slices/lessons/GetExpirePdfSlicer";

//reviews
import postReviewSlicer from "./slices/reviews/PostReviewSlicer";
//language
import getLanguageSettingsSlicer from "./slices/language/GetLanguageSettingsSlicer";

const store = configureStore({
    reducer:{
        user : userReduser,
        course : coursesReducer,
        contactSlice,
        
        categoriesSlicer,
        getSingleCategorySlicer,
        
        // coursesSlicer,
        singleCourseSlicer,
        lessonsSlicers,
        shwoLessonSlicer,
        showQuizesSlicers,
        subscribeRequestSlicer,
        activeCourseSlicer,
        getStudentCoursesSlicer,
        getBestSellingCoursesSlicer,
        
        profileSlicer,
        subscriptionsCoursesSlicer,

        postStudentAnswersSlicer,

        getTeachersSlicer,
        getSingleTeacherSlicer,

        changePasswordSlicer,
        editUserImageSlicer,
        resetPassSlicer,
        forgotPassSlicer,
        getVerifyEmailSlicer,
        postVeryfyEmailSlicer,
        postVerifingCodeSlicer,

        postMessageSlicer,
        getRankedStudentsSlicer,
        
        getLevelsSlicer,
        GetAllCoursesSlicer,
        getMainQuizeProgressSlicer,

        readAllNotficationsSlicer,
        getNotficationSlicer,

        getAttemptSlicer,
        getAllAtemptsSlicer,

        getUiSettingsSlicer,
        postCompletingLessonSlicer,

        applayCouponeSlicer,
        checkoutSlicer,

        registerSlicer,
        downloadLessonFileSlicer,

        setLessonWhichWillCompletItSlicer,
        showSingleLessonSlicer,
        showStreamLessonSlicer,
        showLessonQuizeSlicer,
        getExpireVideoSlicer,
        getExpirePdfSlicer,

        postReviewSlicer,
        getLanguageSettingsSlicer,
    }
})

export default store ;