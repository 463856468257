import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const getSingleCategoryFunc = createAsyncThunk("category/get/single", async (id) => {
  // return await fetch(`${process.env.REACT_APP_PUBLIC_API}/api/categories
  // `, {
  //   method: "GET",})
  //   .then((res) => res.json())
  //   .then((data) => data)
  //   .catch((err) => err);
  const response = await axios.get(`${process.env.REACT_APP_PUBLIC_API}/api/categories/${id}`)
  checkErrorsFunctions(response , response.data.errors);
  return response.data;
});

const getSingleCategorySlicer = createSlice({
  name: "category/single",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getSingleCategoryFunc.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getSingleCategoryFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // if (action.payload.status ==  true) {
      // } else {
      //   state.error = action.payload.message;
      // }
    });
    builder.addCase(getSingleCategoryFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default getSingleCategorySlicer.reducer;
