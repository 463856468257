import { useEffect } from "react";
import ContactForm from "./component/ui/ContactForm";

export default function Contact() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <ContactForm />
    </>
  );
}
