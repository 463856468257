// import { useEffect, useState } from "react";
// import successPurchase from "../../images/cart-page/Successful purchase.gif";
// import feildPurchase from "../../images/cart-page/undraw_Warning_re_eoyh.png";
// import { Link } from "react-router-dom";
// function PaymentStatusPage () {
//     const [purchaseStatus , setPurchaseStatus] = useState()
//     useEffect(() => {
//         const mainUrl = new URL(window.location.href);
//         const pending = mainUrl.searchParams.get("pending");
//         const success = mainUrl.searchParams.get("success");
//         if(pending === "false" && success === "true") {
//             setPurchaseStatus(true)
//         }else {
//             setPurchaseStatus(false)
//         }
//     },[])
//     return (
//         <>
//             <div className="relative w-full min-h-[100vh] flex justify-center items-center">
//                 <div className="page-container relative w-[90%] flex justify-center items-center flex-col">
//                     <div className="img relative mb-2">
//                         {
//                             purchaseStatus
//                             ?
//                             <img src={successPurchase} alt="Successful purchase" />
//                             :
//                             <img src={feildPurchase} alt="Successful purchase" className="relative w-[300px]" />
//                         }
//                     </div>
//                     {
//                         purchaseStatus
//                         ?
//                         <Link to="/">العوده للرئيسية</Link>
//                         :
//                         <div className="relative flex flex-col justify-center items-center">
//                             <p>فشلت عملية الدفع!</p>
//                             <div className="relative flex justify-center items-center gap-2 mt-2">
//                                 <Link 
//                                     to="#" 
//                                     onClick={() => window.history.back()}
//                                     className="relative retry-btn group p-2 bg-[#6c63ff] text-white transition-all duration-500 rounded-md text-[15px] flex justify-center items-center hover:translate-y-[-4px] hover:bg-slate-800"
//                                 >اعادة المحاولة
//                                 <i className="fa-solid fa-rotate-right mr-1 group-hover:rotate-[90deg] transition-all duration-500"></i></Link>
//                                 <Link 
//                                     to="/"
//                                     className="relative text-[15px]"
//                                 > العودة للرئيسية</Link>
//                             </div>
//                         </div>
//                     }
//                 </div>
//             </div>
        
//         </>
//     )
// }
// export default PaymentStatusPage;
import { useEffect, useState } from "react";
import successPurchase from "../../images/cart-page/Successful purchase.gif";
import feildPurchase from "../../images/cart-page/undraw_Warning_re_eoyh.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PaymentStatusPage () {
    const { t } = useTranslation();
    const [purchaseStatus , setPurchaseStatus] = useState();
    
    useEffect(() => {
        const mainUrl = new URL(window.location.href);
        const pending = mainUrl.searchParams.get("pending");
        const success = mainUrl.searchParams.get("success");
        if(pending === "false" && success === "true") {
            setPurchaseStatus(true)
        } else {
            setPurchaseStatus(false)
        }
    },[]);
    
    return (
        <>
            <div className="relative w-full min-h-[100vh] flex justify-center items-center">
                <div className="page-container relative w-[90%] flex justify-center items-center flex-col">
                    <div className="img relative mb-2">
                        {
                            purchaseStatus
                            ?
                            <img src={successPurchase} alt={t("Successful purchase")} />
                            :
                            <img src={feildPurchase} alt={t("Payment Failed")} className="relative w-[300px]" />
                        }
                    </div>
                    {
                        purchaseStatus
                        ?
                        <Link to="/">{t("Return to Home")}</Link>
                        :
                        <div className="relative flex flex-col justify-center items-center">
                            <p>{t("Payment Failed!")}</p>
                            <div className="relative flex justify-center items-center gap-2 mt-2">
                                <Link 
                                    to="#" 
                                    onClick={() => window.history.back()}
                                    className="relative retry-btn group p-2 bg-[#6c63ff] text-white transition-all duration-500 rounded-md text-[15px] flex justify-center items-center hover:translate-y-[-4px] hover:bg-slate-800"
                                >
                                    {t("Retry")}
                                    <i className="fa-solid fa-rotate-right mr-1 group-hover:rotate-[90deg] transition-all duration-500"></i>
                                </Link>
                                <Link 
                                    to="/"
                                    className="relative text-[15px]"
                                >
                                    {t("Return to Home")}
                                </Link>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default PaymentStatusPage;
