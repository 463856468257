// import { useDispatch, useSelector } from "react-redux";
// import verifyImg from "../../images/check-mark-checklist-icon-correct-approved-symbol-3d-render-illustration_790767-95-removebg-preview.png";
// import { postVeryfyEmailFunc } from "../../store/slices/users/PostVerifyEmailSlicer";
// import { useState } from "react";
// import OverLayLoading from "../../component/global/overlay-loading/OverLayLoading";
// import loginBg from "../../images/login-bg.jpg"
// import "./verify-email.css";
// import { postVerifingCodeFunc } from "../../store/slices/users/PostVerifingCodeSlicer";
// import { Button } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import { getProfileData } from "../../store/slices/users/profileSlicer";
// import RotateLeftIcon from '@mui/icons-material/RotateLeft';
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// function VerifyEmailPage() {
//     const {t} = useTranslation();
//     const verifyEmailApi = `${process.env.REACT_APP_PUBLIC_API}/api/email/verification-notification`;
//     const verifingCodeApi = `${process.env.REACT_APP_PUBLIC_API}/api/verify-email`;
//     const dispatch = useDispatch();
//     const navigate = useNavigate()
//     const {loading:postVerifingLoading} = useSelector(
//         state => state.postVeryfyEmailSlicer
//     )
//     const {loading:postVerifingCodeLoading} = useSelector(
//         state => state.postVerifingCodeSlicer
//     )
//     const [code , setCode] = useState(null)
//     const [sendingMessage , setSendingMessage] = useState(t("يرجي تاكيد البريد الالكتروني"));
//     const getVerfingCode = () => {
//         dispatch(postVeryfyEmailFunc({api : verifyEmailApi , data : {}}))
//         .then(result => {
//             if(result.payload?.success) {
//                 setSendingMessage(result.payload?.message);
//             }else {
//                 console.log(result.payload)
//                 if(result.payload?.response?.data?.message === "site.already_verified") {
//                     navigate("/");
//                 }
//             }
//         })
//     }
//     const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
//         const getProfile = async () => {
//           try {
//             dispatch(getProfileData(profileApi))
//           } catch (error) {
//             console.log(error);
//           }
//         };
//     const postVerfingCode = () => {
//         dispatch(postVerifingCodeFunc({api : verifingCodeApi , data : {code : code}}))
//         .then(result => {
//             console.log(result)
//             if(result.payload.success) {
//                 getProfile();
//                 navigate("/");
//                 toast.success(result.payload.message, {
//                     position: "top-right",
//                     autoClose: 5000,
//                     hideProgressBar: false,
//                     closeOnClick: true,
//                     pauseOnHover: true,
//                     draggable: true,
//                     progress: undefined,
//                 });
//             }
//         })
//     }
//     return (
//         <>
//             <div className="page-content relative w-full h-[100vh]"
//                 style={{backgroundImage: `url(${loginBg})` , backgroundPosition: "center center" , backgroundRepeat: "no-repeat" , backgroundSize: "cover"}}
//             >
//                 <div className="page-container relative w-[90%] mx-auto flex flex-col justify-center items-center h-full">
//                     <img src={verifyImg} alt="verify img" className="relative mb-3 w-[300px] verify-email-image" />
//                     <div className="sending-conetnt relative flex flex-col justify-center items-center">
//                         <h3 className="mb-3">{t(sendingMessage)}</h3>
//                         <form 
//                         id="post-verify-code-form"
//                         onSubmit={(e) => {
//                             e.preventDefault();
//                             postVerfingCode()
//                         }}
//                         className="">
//                             <input 
//                             type="text" 
//                             placeholder="* * * * *" 
//                             value={code}
//                             onChange={(e) => setCode(e.target.value)}
//                             className="w-full md:w-[200px] shadow-none text-center"
//                             disabled = {(postVerifingLoading || postVerifingCodeLoading)}
//                             />
//                         </form>
//                         <div className="mt-1 relative flex items-center">
//                             {
//                                 // </button>
//                                 <Button type="submit" form="post-verify-code-form"  variant="contained" color="success"
//                                 className={`relative mt-3 rounded-md ${(postVerifingLoading || postVerifingCodeLoading || !code) ? "pointer-events-none opacity-70" : ""}  mx-2`}
//                                 >
//                                     {
//                                         postVerifingCodeLoading
//                                         ?
//                                         t("جاري التاكيد..")
//                                         :
//                                         t("تاكيد")
//                                     }
//                                 </Button>
//                             }
//                             <Button variant="contained"
//                                 onClick={() => {
//                                     getVerfingCode()
//                                 }}
//                                 className={`relative mt-3 px-3 ${(postVerifingLoading || postVerifingCodeLoading) ? "pointer-events-none opacity-70" : ""} overflow-hidden`}
//                             >
//                                 {t("اعادة الارسال")}
//                                 <RotateLeftIcon/>
//                             </Button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         <OverLayLoading loading={postVerifingLoading} loadingtext={t("جاري الارسال..")}/>
//         </>
//     )
// }
// export default VerifyEmailPage;

import { useDispatch, useSelector } from "react-redux";
import verifyImg from "../../images/check-mark-checklist-icon-correct-approved-symbol-3d-render-illustration_790767-95-removebg-preview.png";
import { postVeryfyEmailFunc } from "../../store/slices/users/PostVerifyEmailSlicer";
import { useState } from "react";
import OverLayLoading from "../../component/global/overlay-loading/OverLayLoading";
import loginBg from "../../images/login-bg.jpg"
import "./verify-email.css";
import { postVerifingCodeFunc } from "../../store/slices/users/PostVerifingCodeSlicer";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getProfileData } from "../../store/slices/users/profileSlicer";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function VerifyEmailPage() {
    const { t } = useTranslation();
    const verifyEmailApi = `${process.env.REACT_APP_PUBLIC_API}/api/email/verification-notification`;
    const verifingCodeApi = `${process.env.REACT_APP_PUBLIC_API}/api/verify-email`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading: postVerifingLoading } = useSelector(
        state => state.postVeryfyEmailSlicer
    );
    const { loading: postVerifingCodeLoading } = useSelector(
        state => state.postVerifingCodeSlicer
    );
    const [code, setCode] = useState(null);
    const [sendingMessage, setSendingMessage] = useState(t("Please confirm your email address"));

    const getVerfingCode = () => {
        dispatch(postVeryfyEmailFunc({ api: verifyEmailApi, data: {} }))
            .then(result => {
                if (result.payload?.success) {
                    setSendingMessage(result.payload?.message);
                } else {
                    console.log(result.payload);
                    if (result.payload?.response?.data?.message === "site.already_verified") {
                        navigate("/");
                    }
                }
            });
    };

    const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;

    const getProfile = async () => {
        try {
            dispatch(getProfileData(profileApi));
        } catch (error) {
            console.log(error);
        }
    };

    const postVerfingCode = () => {
        dispatch(postVerifingCodeFunc({ api: verifingCodeApi, data: { code: code } }))
            .then(result => {
                console.log(result);
                if (result.payload.success) {
                    getProfile();
                    navigate("/");
                    toast.success(result.payload.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            });
    };

    return (
        <>
            <div className="page-content relative w-full h-[100vh]"
                style={{ backgroundImage: `url(${loginBg})`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
            >
                <div className="page-container relative w-[90%] mx-auto flex flex-col justify-center items-center h-full">
                    <img src={verifyImg} alt="verify img" className="relative mb-3 w-[300px] verify-email-image" />
                    <div className="sending-conetnt relative flex flex-col justify-center items-center">
                        <h3 className="mb-3">{t("Please confirm your email address")}</h3>
                        <form
                            id="post-verify-code-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                postVerfingCode();
                            }}
                            className="">
                            <input
                                type="text"
                                placeholder="* * * * *"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                className="w-full md:w-[200px] shadow-none text-center"
                                disabled={(postVerifingLoading || postVerifingCodeLoading)}
                            />
                        </form>
                        <div className="mt-1 relative flex items-center">
                            <Button
                                type="submit"
                                form="post-verify-code-form"
                                variant="contained"
                                color="success"
                                className={`relative mt-3 rounded-md ${(postVerifingLoading || postVerifingCodeLoading || !code) ? "pointer-events-none opacity-70" : ""} mx-2`}
                            >
                                {postVerifingCodeLoading
                                    ? t("Confirming...")
                                    : t("Confirm")}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    getVerfingCode();
                                }}
                                className={`relative mt-3 px-3 ${(postVerifingLoading || postVerifingCodeLoading) ? "pointer-events-none opacity-70" : ""} overflow-hidden`}
                            >
                                {t("Resend")}
                                <RotateLeftIcon />
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <OverLayLoading loading={postVerifingLoading} loadingtext={t("Sending...")} />
        </>
    );
}

export default VerifyEmailPage;
