import Swal from "sweetalert2";
import { logout } from "../../services/logoutAction";
import { toast } from "react-toastify";

export default function checkErrorsFunctions (response , errors) {
    if(errors) {
        if(Object.keys(errors).length >= 1) {
            const errorKey = Object.keys(errors)[0]; // Get the key of the first error
            const errorMessage = errors[errorKey]; // Get the error message
            Object.keys(errors).forEach((errorKey) => {
                const errorMessage = errors[errorKey][0]; // Get the error message
                // Swal.fire({
                //     // title: errorKey,
                //     title: "خطا",
                //     text: errorMessage,
                //     icon: "error"
                // });
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });
        }else {
            // Swal.fire({
            //     title: "عذرا",
            //     text: response.data.message,
            //     icon: "error"
            // });
            toast.error(response.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    else {
        if(response.data.success !== true && response.data.message) {
            if(response.data.status_code === 401){
                // Swal.fire({
                //     title: "يلزم تسجيل الدخول!",
                //     text: "سيتم تحويلك الي صفحة تسجيل الدخول",
                //     icon: 'error',
                //     confirmButtonText: 'تم'
                // });
                setTimeout(() => logout() , 2000);
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }else {
                // Swal.fire({
                //     title: "عذرا!",
                //     text: response.data.message,
                //     icon: 'error',
                //     confirmButtonText: 'تم'
                // });
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
        else{
            if(response.data.success === true && response.data.message){
                // Swal.fire({
                //     title: "احسنت!",
                //     text: response.data.message,
                //     icon: "success"
                // });
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        }
    }
}