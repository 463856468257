import { Outlet } from "react-router-dom";
import Navbar from "../../component/Nav/Navbar";
import PageHeader from "../../component/ui/PageHeader";
import MobileNavbar from "../../component/Nav/MobileNavbar";
import SearchBar from "../../component/ui/SearchBar";
import ScrollUp from "../../component/ui/ScrollUp";
import Footer from "../../component/ui/Footer";
import Wrappers from "../../component/ui/Wrappers";
import AllCourses from "./AllCourses";
import Offcanvan from "../../component/Nav/Offcanvan";
import { useEffect } from "react";

export default function Courses() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
         <AllCourses /> 

    </>
  );
}
