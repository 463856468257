import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const readAllNotficationsFunc = createAsyncThunk(
    "read/nots", 
    async (api) => {
        try {
            const response = await axios.post(api  , {});
            // checkErrorsFunctions(response , response.data.errors);
            return response.data
        }
        catch (error) {
            // checkErrors(error.response , error.message);
            return error
        }
    }

);

const readAllNotficationsSlicer = createSlice({
  name: "notfications",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(readAllNotficationsFunc.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(readAllNotficationsFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload
    });
    builder.addCase(readAllNotficationsFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default readAllNotficationsSlicer.reducer;
