import { useEffect } from "react";
import PageHeader from "../../component/ui/PageHeader";
import Partners from "../../component/ui/Partners";
import AboutSection from "./component/AboutSection";
import Counter from "./component/Counter";
import Teachers from "./component/Teachers";
import aboutHeaderImg from "../../images/about/page-header-bg.jpg";
import Whyus from "../Home/component/Whyus";

export default function About() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
      <PageHeader title="Who Are We?" headerImg={aboutHeaderImg}/> 
      <AboutSection />
      <Whyus />
      <Partners />
      {/* <Counter /> */}
      {/* <Teachers /> */}
    </>
  );
}
