import { useEffect, useState } from "react";
import "./search-overlay.css";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { coursesApis, fetchAllCoursesFunc } from "../../../store/slices/courses/coursesSlicer";
import axios from "axios";
import coursesSlicer from "../../../store/slices/coursesSlicer";
import CustomLoaders from "../CustomLoaders";
function CustomSearchOverLay({showSearch , hideSearch}) {
    const [searchValue , setSearchValue] = useState("");
    const [coursesList , setCoursesList] = useState([])
    const coursesApi = `${process.env.REACT_APP_PUBLIC_API}/api/courses`;
    const [searchURL , setSearchURL] = useSearchParams();
    const [doSearch , setDoSearch] = useState();
    const [courses , setCourses] = useState([]);
    const [noLoading , setNoLoading] = useState();
    const dispatch = useDispatch()
    const { loading, courses:coursesFromApi } = useSelector((s) => s.GetAllCoursesSlicer);

    useEffect(() => {
      const searchApi = `${coursesApi}?${searchValue ? `search=${searchValue}` : ""}`
      let searchTimeout;
      const getCourses = () => {
        if(searchValue !== "") {
          clearTimeout(searchTimeout)
          searchTimeout = setTimeout(() => dispatch(fetchAllCoursesFunc(searchApi)),2000)
        }else {
          setCourses([])
        }
      }
      getCourses();
      return () => clearTimeout(searchTimeout);
    },[searchValue]);
  
    useEffect(() => {
      coursesFromApi && setCourses(coursesFromApi)
    },[coursesFromApi])

    useEffect(() => {
        !loading ? setTimeout(()=>setNoLoading(true),100) : setNoLoading(false)
    },[loading])

    
    return (
        <>
        
            <div className={`fixed z-[999999] search-overlay ${showSearch ? "active" : ""} top-0 left-0 w-full h-[100vh] flex flex-col justify-center items-center transition-all duration-500`}>
                <i className="fa-solid fa-xmark absolute top-[20px] right-[20px] text-blue-600 transition-all duration-500 text-[30px] hover:rotate-[180deg] hover:scale-105 cursor-pointer" 
                    onClick={()=>{
                        hideSearch();
                        setSearchValue("");
                    }}
                ></i>
                <div className="div-input relative overflow-hidden flex bg-black sm:w-[300px] md:w-[400px]">
                    <button to={`/courses/${searchValue}`} className={`search-btn flex justify-center items-center transition-all duration-500 p-3 ${searchValue !== "" ? "text-blue-500 mr-0" : "mr-[-50px]"}`}
                        onClick={()=>{
                            // setDoSearch(true)
                        }}
                    >
                        <i className="fa fa-search transition-all duration-300" aria-hidden="true" />
                    </button>
                    <input type="text" placeholder="البحث عن كورسات.." className="text-white bg-transparent w-full"
                        onChange={(e)=>setSearchValue(e.target.value)}
                        value={searchValue}
                    />
                </div>
                {
                    loading &&
                    (
                        <div className="text-center py-1">
                            <CustomLoaders />
                            <CustomLoaders />
                            <CustomLoaders />
                        </div>
                    )
                }
                <div className={`relative courses-parent mt-3 flex flex-col w-[90%] justify-center items-center max-h-[200px] overflow-y-auto ${showSearch ? "flex" : "hidden"} ${!loading ? "active" : ""}`}>
                    {
                        coursesFromApi && courses.length >= 1 ?
                        courses.map((course , index) => {
                            return (
                                <div style={{animationDelay : `.${index}s`}} key={index} className={`course-div relative my-2 bg-black rounded-md min-h-[50px] overflow-hidden transition-all duration-300 p-2 sm1:w-full md:w-[400px] flex items-center justify-between ${noLoading ? "active" : ""}`}>
                                    <h3 className="course-name text-white">
                                        {course.name}
                                    </h3>
                                    <Link to={`courses/${course.id}`}
                                        onClick={() => {
                                            hideSearch()
                                            setSearchValue("")
                                        }}
                                    >
                                        <i className="fa-regular fa-eye text-blue-500"></i>
                                    </Link>
                                </div>
                            )
                        })
                        :
                        <h2 className="text-blue-500">No Courses</h2>
                    }
                </div>
            </div>

        
        </>
    )
}


export default CustomSearchOverLay;