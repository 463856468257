import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next";
import { Button, Spinner } from "react-bootstrap";
import failedImg from "../../../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
import { useNavigate } from "react-router-dom";
import CategoryCard from "./CategoryCard";

export default function CategoriesSection() {
    const {t} = useTranslation();
    const {loading:getCategoriesLoading , data:categoriesData} = useSelector(
        state => state.categoriesSlicer
    )
    const [sliceCount , setSliceCount] = useState(3)
    const navigate = useNavigate();
    const [subCategoriesList , setSubCategoriesList] = useState([])
    useEffect(() => {
    if(categoriesData) {
        setSubCategoriesList(categoriesData?.data?.categories)
    }
    },[categoriesData])
    
    const showCategory = (id) => {
        navigate(`/courses?category=${id}`)
    }
    return (
        <>
        <div className="section relative py-[100px]">
            <div className="section-title text-center">
                {/* <span className="section-title__tagline text-[#00a4e5]">{t("Browse Categories")}</span> */}
                <h2 className="section-title__title">{t("Explore Our Categories")}</h2>
            </div>
            <div className="container">
                <div data-aos="fade-up" className="aos row gap-3 justify-center">
                    {
                        getCategoriesLoading
                        ?
                        <>
                            <div className="row d-flex justify-content-center gap-2">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                            </div>
                        </>
                        :
                        categoriesData
                        ?
                        (
                          subCategoriesList?.length >= 1
                          ?
                          (
                            subCategoriesList?.slice(0 , sliceCount).map((cat , index) => (
                              <CategoryCard
                                key={index}
                                id={cat.id}
                                name={cat.name}
                                image={cat.image_url}
                                subCategoriesCount={cat.all_children?.length}
                                showCategory={() => showCategory(cat.id)}
                              />
                            ))
                          )
                          :
                          <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                            <img
                              loading="lazy"
                              src={failedImg}
                              alt="failed img"
                              className="w-[150px] mb-3"
                            />
                            <p className="">
                              {t("No Categories ")}!
                            </p>
                          </div>
                        )
                        :
                        <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                          <img
                            loading="lazy"
                            src={failedImg}
                            alt="failed img"
                            className="w-[150px] mb-3"
                          />
                          <p className="">
                            {t("Faild Load Categories ")}!
                          </p>
                        </div>

                    }
                </div>
                {
                    subCategoriesList?.length > 3
                    ?
                    <div className="relative flex justify-center items-center my-[20px]">
                        <Button
                            className={`bg-primary border-none outline-none shadow-none mx-2 ${sliceCount >= subCategoriesList?.length ? "pointer-events-none opacity-50" : ""}`}
                            onClick={() => {
                                setSliceCount(prev => prev+3)
                            }}
                        >{t("Show More")}</Button>
                        {
                            sliceCount >= subCategoriesList?.length
                            ?
                            <Button
                                className={`bg-primary border-none outline-none shadow-none mx-2 ${subCategoriesList?.length <= 3 ? "pointer-events-none opacity-50" : ""}`}
                                onClick={() => {
                                    setSliceCount(prev => prev-3)
                                }}
                            >{t("Show Less")}</Button>
                            :
                            ""
                        }
                    </div>
                    :
                    ""
                }
            </div>
        </div>
        </>
    )
}