import { Link } from "react-router-dom";
// import "./toggeler-lang.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getLanguageSettingsFunc } from "../../store/slices/language/GetLanguageSettingsSlicer";
import PublicIcon from '@mui/icons-material/Public';
export default function ToggelerLang() {
	const {loading:getLanguageLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
	const [languagesList , setLanguagesList] = useState([])
	useEffect(() => {
		languageData && languageData.languages && setLanguagesList(languageData.languages);
	},[languageData])
  const languageAPi = `${process.env.REACT_APP_PUBLIC_API}/api/language-settings`;
  const dispatch = useDispatch();
  const [langSlug , setlangSlug] = useState("")
  useEffect(() => {
    if(languageData && languageData.defaultLang) {
      const dfLangSlug = languageData.defaultLang.slug
      setlangSlug(dfLangSlug)
    }
  },[languageData])
  const handleGetLang = (lang) => {
    if(lang.slug) {
      if(lang.slug !== langSlug) {
        dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${lang.slug}`));
      }
    }
  }
  return (
    <div className="dropdown">
      <button className="btn btn-secondary dropdown-toggle toggeler-lang after:hidden border-none shadow-none " type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{backgroundColor: "transparent" , color : "#0f0f0f" , borderColor : "transparent" , fontSize : "15px"}}>
        <div className="relative flex items-center group">
          <p className="relative transition-all duration-300 translate-x-[-10px] opacity-0 group-hover:translate-x-0 group-hover:opacity-100">{langSlug}</p>
          <PublicIcon className="transition-all duration-300 hover:text-primary"/>
        </div>
      </button>
        <ul className="dropdown-menu flex-column">
        {
            getLanguageLoading
            ?
            "جاري التحميل..."
            :
            (
                languagesList && languagesList.length >= 1
                ?
                languagesList.map((lang , index) => (
                <li key={index} className="d-block m-0" style={{padding : 0}}
                onClick={() => handleGetLang(lang)}
                > <Link className="dropdown-item" to="#">{lang?.slug?.toUpperCase()}</Link></li>
                ))
                :
                <li><Link className="dropdown-item" to="#" style={{pointerEvents : "none"}}>No Languages</Link></li>  
            )
        }
        </ul>
    </div>
  );
}