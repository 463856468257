import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../global/checkResponseError";

export const postStudentAnswersFunc = createAsyncThunk(
    "post student answers func", 
    async ({url , answers}) => {
    try {
        const response = await axios.post(url,answers);
        return response.data;
    } catch (error) {
        checkErrors(error.response , error.message);
        return error;
    }
});

const postStudentAnswersSlicer = createSlice({
  name: "post student answers slicer",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  // reducers : {
  //   clearQuizeData : (state , action) => {
  //     state.data = null
  //   },
  // },
  extraReducers: (builder) => {
    builder.addCase(postStudentAnswersFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(postStudentAnswersFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(postStudentAnswersFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
// export const {clearQuizeData} = postStudentAnswersSlicer.actions;
export default postStudentAnswersSlicer.reducer;
