import { createSlice } from "@reduxjs/toolkit";

const setLessonWhichWillCompletItSlicer = createSlice({
    name : "lesson/target/whichWillCompolet",
    initialState : {
        loading: null,
        data : null,
        error : null
    },
    reducers : {
        targetLessonWhichComplete : (state , action) => {
            state.data = action.payload;
            console.log(state)
            console.log(action)
        }
    }
})
export default setLessonWhichWillCompletItSlicer.reducer;
export const {targetLessonWhichComplete} = setLessonWhichWillCompletItSlicer.actions
