import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const editUserImageFunc = createAsyncThunk(
    "edit user image function",
    async ({api , image}) => {
        try {
            const response = await axios.post(api , image);
            // checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message)
        }
    }
)

const editUserImageSlicer = createSlice({
    name : "edit user image slicer",
    initialState : {
        editImageLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(editUserImageFunc.pending , (state) => {
            state.editImageLoading = true
            state.data = null
            state.error = false
        });
        builder.addCase(editUserImageFunc.rejected , (state) => {
            state.editImageLoading = false
            state.data = null
            state.error = true
        });
        builder.addCase(editUserImageFunc.fulfilled , (state , action) => {
            state.editImageLoading = null
            state.data = action.payload
            state.error = null
        });
    }
})

export default editUserImageSlicer.reducer