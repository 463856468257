import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";
export const fetchAllCoursesFunc = createAsyncThunk(
    "get all courses function",
    async (api) => {
        try {
            const response = await axios.get(api);
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.courseExecution;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error;
        }
        
    }
)

const GetAllCoursesSlicer = createSlice({
    name : "get all courses slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(fetchAllCoursesFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(fetchAllCoursesFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(fetchAllCoursesFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default GetAllCoursesSlicer.reducer;
