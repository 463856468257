import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLessonData } from "../../store/slices/lessons/showLessonSlicer";
import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import CustomLoaders from "../../component/ui/CustomLoaders";
import faildImg from "../../images/planet.png";
import PageHeader from "../../component/ui/PageHeader";
import headreImage from "../../images/photo-1529390079861-591de354faf5.webp"

function SingleLessonPage() {
    const { lessonId } = useParams();
    const {
        loading: showLessonsLoading,
        data: showLessonsData,
        error: showLessonsError,
      } = useSelector((s) => s.shwoLessonSlicer);
      const [singleLesson , setSingleLesson] = useState({})
      const dispatch = useDispatch();
      const getLesson = () => {
        try {
            dispatch(
                getLessonData(`${process.env.REACT_APP_PUBLIC_API}/api/lessons/${lessonId}`)
                )
                .then(result => {
                  console.log(result)
            })
        }
        catch(error) {
            Swal.fire({
                title: " عذرا!",
                text: error,
                icon: "warning"
              });
        }
      }
      useEffect(() => {
        getLesson()
      },[])
      useEffect(() => {
        showLessonsData && setSingleLesson(showLessonsData.lesson)
        showLessonsData && console.log(showLessonsData)
      },[showLessonsData])
    return (
        <>
        <PageHeader title={"درس مجاني"} headerImg={headreImage}/>
        <div className="page-content relative w-full min-h-[100vh]">
            <div className="page-container relative w-[90%] mx-auto py-[20px] flex justify-center items-center">
                {
                    showLessonsLoading
                    ?
                    (
                        <div className="row">
                            <div className="text-center py-1">
                                <CustomLoaders />
                                <CustomLoaders />
                                <CustomLoaders />
                            </div>
                        </div>
                    )
                    :
                    (
                        showLessonsData
                        ?
                        (
                            singleLesson
                            &&
                            <div className="lesson-content relative">
                                <div className="lesson-content-header relative flex flex-col">
                                    <div className="relative flex items-center mb-1">
                                        <p className="mb-0 ml-1 text-sm">الدرس</p>
                                        :
                                        <p className="mb-0 mr-1 text-sm">{singleLesson.name}</p>
                                    </div>
                                    <div className="relative flex items-center mb-1">
                                        <p className="mb-0 ml-1 text-sm">الوصف</p>
                                        :
                                        <div dangerouslySetInnerHTML={{__html : singleLesson.description}} />
                                    </div>
                                    <div className="relative flex items-center mb-1">
                                        <p className="mb-0 ml-1 text-sm">ترتيب الدرس</p>
                                        :
                                        <div dangerouslySetInnerHTML={{__html : singleLesson.order}} />
                                    </div>
                                </div>
                                <h3 className="relative my-2 text-slate-900">محتوي الدرس</h3>
                                <div dangerouslySetInnerHTML={{__html : singleLesson.contents}} />
                            </div>
                        )
                        :
                        <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                            <img 
                            loading="lazy"
                            src={faildImg}
                            alt="faild img"
                            className="w-[150px] mb-3"
                            />
                            <p>
                            تعذر تحميل الدرس المجاني!
                            </p>
                        </div>
                    )
                }
            </div>
        </div>
        
        </>
    )
}

export default SingleLessonPage;