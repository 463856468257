// import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
// import Modal from 'react-bootstrap/Modal';
// import { useDispatch, useSelector } from 'react-redux';
// import { activeCourseFunc } from '../../../store/slices/courses/activation-actions/ActiveCourseSlicer';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

// export default function ActivationCodeModal(props) {
//     const {t} = useTranslation();
//     const {course_execution_id} = props;
//     const [activationCode , setActiationCode] = useState("");
//     const {loading:activationLoading} = useSelector(
//         state => state.activeCourseSlicer
//     )
//     const activationApi = `${process.env.REACT_APP_PUBLIC_API}/api/activation-course`;
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const activationFunction = () => {
//         dispatch(activeCourseFunc({api : activationApi , data : {activation_code : activationCode , course_execution_id}}))
//         .then(result => {
//             if(result.payload.success === true) {
//               toast.success(result?.payload?.message, {
//                 position: "top-right",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//               });
//                 props.onHide();
//                 setActiationCode("")
//                 navigate(`/success-activation/${course_execution_id}`);
//             }
//         })
//     }
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter" className='relative w-full flex justify-end items-center text-[17px]'>
//           {t("تفعيل الكورس")}
//           <i className="fa-solid fa-chart-line text-slate-700 ml-1"></i>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <div className='relative w-full'>
//             <form
//             id="activation-form"
//             onSubmit={(e) => {
//                 e.preventDefault();
//                 activationFunction()
//             }}
//             >
//                 <div className='relative flex flex-col justify-center items-center'>
//                     <div className='activation-input relative col-sm-6 flex flex-col justify-center items-center'>
//                         <h4 className='mb-2 text-slate-700 text-[17px]'>{t("قم بادخال كود التفعيل")}</h4>
//                         <input
//                             className='relative form-control mb-2 py-1'
//                             placeholder='XXXXX'
//                             onChange={(e) => setActiationCode(e.target.value)}
//                         />
//                     </div>
//                     <button class={`cursor-pointer transition-all duration-500 hover:translate-y-[-3px] mt-3 hover:rounded-md shadow-md hover:shadow-slate-500 bg-primary font-semibold overflow-hidden relative z-100 border-primary text-white px-8 py-2 border-none outline-none flex justify-center items-center text-[17px] ${(activationLoading || activationCode === "") ? "pointer-events-none opacity-50" : ""}`}>
//                         {
//                             activationLoading
//                             ?
//                             t("جاري التفعيل..")
//                             :
//                             <>
//                             {t("تفعيل")}
//                             <i className="fa-solid fa-hand-pointer ml-1"></i>
//                             </>
//                         }
//                     </button>
//                 </div>
//             </form>
//         </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <button onClick={props.onHide}>{t("اغلاق")}</button>
//       </Modal.Footer>
//     </Modal>
//   );
// }
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { activeCourseFunc } from '../../../store/slices/courses/activation-actions/ActiveCourseSlicer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ActivationCodeModal(props) {
    const { t } = useTranslation();
    const { course_execution_id } = props;
    const [activationCode, setActiationCode] = useState("");
    const { loading: activationLoading } = useSelector(
        state => state.activeCourseSlicer
    );
    const activationApi = `${process.env.REACT_APP_PUBLIC_API}/api/activation-course`;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const activationFunction = () => {
        dispatch(activeCourseFunc({ api: activationApi, data: { activation_code: activationCode, course_execution_id } }))
        .then(result => {
            if (result.payload.success === true) {
                toast.success(result?.payload?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                props.onHide();
                setActiationCode("");
                navigate(`/success-activation/${course_execution_id}`);
            }
        });
    };
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='relative w-full flex justify-end items-center text-[17px]'>
                    {t('Activate Course')}
                    <i className="fa-solid fa-chart-line text-slate-700 ml-1"></i>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='relative w-full'>
                    <form
                        id="activation-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            activationFunction();
                        }}
                    >
                        <div className='relative flex flex-col justify-center items-center'>
                            <div className='activation-input relative col-sm-6 flex flex-col justify-center items-center'>
                                <h4 className='mb-2 text-slate-700 text-[17px]'>{t('Enter activation code')}</h4>
                                <input
                                    className='relative form-control mb-2 py-1'
                                    placeholder='XXXXX'
                                    onChange={(e) => setActiationCode(e.target.value)}
                                />
                            </div>
                            <button className={`cursor-pointer transition-all duration-500 hover:translate-y-[-3px] mt-3 hover:rounded-md shadow-md hover:shadow-slate-500 bg-primary font-semibold overflow-hidden relative z-100 border-primary text-white px-8 py-2 border-none outline-none flex justify-center items-center text-[17px] ${(activationLoading || activationCode === "") ? "pointer-events-none opacity-50" : ""}`}>
                                {
                                    activationLoading
                                    ?
                                    t('Activating...')
                                    :
                                    <>
                                        {t('Activate')}
                                        <i className="fa-solid fa-hand-pointer ml-1"></i>
                                    </>
                                }
                            </button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>{t('Close')}</button>
            </Modal.Footer>
        </Modal>
    );
}
