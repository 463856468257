import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CustomLoaders from './ui/CustomLoaders';
import emptyImg from "../images/psd-3d-empty-trash-icon_460336-2580-removebg-preview.png"
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';

// function NotficationsList({show , onHide , notfications , notficationsLoading}) {

//     const [animate , setAnimate] = useState();
//     useEffect(() => {
//         !notficationsLoading ? setAnimate(true) : setAnimate(false)
//     },[notficationsLoading])
//   return (
//     <>
//       <Modal
//         className='z-[9999999999] notfications-parent'
//         show={show}
//         onHide={onHide}
//         backdrop="static"
//         keyboard={false}
//       >
//         <Modal.Header closeButton>
//           <Modal.Title className='text-right w-full'>قائمه الاشعارات</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//             {
//                 notficationsLoading
//                 ?
//                 <>
//                     <div className="row">
//                         <div className="text-center py-1">
//                         <CustomLoaders />
//                         <CustomLoaders />
//                         <CustomLoaders />
//                         </div>
//                     </div>
//                 </>
//                 :
//                 (   
//                     notfications.length >= 1
//                     ?
//                     <div className='notfications-content relative w-full max-h-[300px] overflow-y-auto'>
//                         {
//                             notfications &&
//                             notfications.map((message , index) => {
//                                 const {data:noteData} = message;
//                                 const {title , subject , sender} = noteData;
//                                 const noteDate = new Date(message.created_at);
//                                 const sendingDay = noteDate.getDate();
//                                 const sendingMonth = noteDate.getMonth()+1;
//                                 const sendingYear = noteDate.getFullYear();
                                
//                                 const readingDate = new Date(message.read_at);
//                                 const readingDay = readingDate.getDate();
//                                 const readingMonth = readingDate.getMonth()+1;
//                                 const readingYear = readingDate.getFullYear();
//                                 return (
//                                     <div style={{animationDelay : `.${index}s`}} key={index} className={`notfication-box relative w-full px-2 py-3 bg-blue-100 hover:text-white rounded-md transition-all duration-300 hover:bg-blue-200 mb-1 flex justify-between items-center ${animate ? "active" : ""}`}>
//                                       <div className='relative flex flex-col'>
//                                         <h4 className={"m-0 text-primary text-[17px] mb-1"}>
//                                           <i className="fa-brands fa-cuttlefish ml-1"></i>
//                                           {title}
//                                         </h4>
//                                         <h5 className='m-0 text-[#555758] flex flex-col text-[17px]'>
//                                           <div dangerouslySetInnerHTML={{__html : subject}} />
//                                           <div className='relative flex flex-col mt-1'>
//                                             <div className='note-date relative flex items-center text-sm font-normal'>
//                                               <i className="fa-solid fa-user ml-1 opacity-90"></i>
//                                               <p className='m-0'>المرسل  <strong className='text-primary'>{sender}</strong></p>
//                                             </div>
//                                             <div className='note-date relative flex items-center text-sm font-normal'>
//                                               <i className="fa-solid fa-calendar-days ml-1 opacity-90"></i>
//                                               <p className='m-0 text-[10px]'>تم الارسال {`${sendingYear} / ${sendingMonth} / ${sendingDay}`}</p>
//                                             </div>
//                                             <div className='read-note-date relative flex items-center text-sm font-normal'>
//                                               <i className="fa-solid fa-eye ml-1 opacity-90"></i>
//                                               <p className='m-0 text-[10px]'>تم القراءه {`${readingYear} / ${readingMonth} / ${readingDay}`}</p>
//                                             </div>
//                                           </div>
//                                         </h5>
//                                       </div>
//                                       <i className="fa-solid fa-comments text-primary text-[18px]"></i>
//                                     </div>
//                                 )
//                             })
//                         }
//                     </div>
//                     :
//                     <div className='relative flex flex-col justify-center items-center'>
//                         <img loading="lazy" src={emptyImg} alt="empty img" className='w-[100px]' />
//                         <p className='text-right mt-3'>لا يوجد اشعارات</p>
//                     </div>
//                 )
//             }
//         </Modal.Body>
//         <Modal.Footer>
//           <Button className=" bg-blue-500 border-blue-500 text-white" onClick={onHide}>
//             اغلاق
//           </Button>
//           {/* <Link to="/all-nots-page"  className="primary bg-primary btn text-white">كل الاشعارات</Link> */}
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }

function NotficationsList({show , onHide , notfications , notficationsLoading}) {
  const {t} = useTranslation();
  const [animate , setAnimate] = useState();
  useEffect(() => {
      !notficationsLoading ? setAnimate(true) : setAnimate(false)
  },[notficationsLoading])
  return (
    <>
      <Offcanvas show={show} onHide={onHide} className="z-[9999999999] notfications-content h-screen overflow-y-auto ">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className='nofication-header'>{t("Notfications")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='flex justify-center items-center'>
        {
                notficationsLoading
                ?
                <>
                    <div className="row">
                        <div className="text-center py-1">
                          <CustomLoaders />
                          <CustomLoaders />
                          <CustomLoaders />
                        </div>
                    </div>
                </>
                :
                (   
                    notfications.length >= 1
                    ?
                    <div className='notfications-content relative w-full max-h-full overflow-y-auto'>
                        {
                            notfications &&
                            notfications.map((message , index) => {
                                const {data:noteData} = message;
                                const {title , subject , sender} = noteData;
                                const noteDate = new Date(message.created_at);
                                const sendingDay = noteDate.getDate();
                                const sendingMonth = noteDate.getMonth()+1;
                                const sendingYear = noteDate.getFullYear();
                                
                                const readingDate = new Date(message.read_at);
                                const readingDay = readingDate.getDate();
                                const readingMonth = readingDate.getMonth()+1;
                                const readingYear = readingDate.getFullYear();
                                return (
                                    <div style={{animationDelay : `.${index}s`}} key={index} className={`notfication-box relative w-full px-2 py-3 bg-blue-100 hover:text-white rounded-md transition-all duration-300 hover:bg-blue-200 mb-1 flex justify-between items-center ${animate ? "active" : ""}`}>
                                      <div className='relative flex flex-col'>
                                        <h4 className={"m-0 text-primary text-[17px] mb-1"}>
                                          <i className="fa-brands fa-cuttlefish ml-1"></i>
                                          {title}
                                        </h4>
                                        <h5 className='m-0 text-[#555758] flex flex-col text-[17px]'>
                                          <div dangerouslySetInnerHTML={{__html : subject}} />
                                          <div className='relative flex flex-col mt-1'>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-user ml-1 opacity-90"></i>
                                              <p className='m-0'>{t("Sender")}  <strong className='text-primary'>{sender}</strong></p>
                                            </div>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-calendar-days ml-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>{t("Sent")}{`${sendingYear} / ${sendingMonth} / ${sendingDay}`}</p>
                                            </div>
                                            <div className='read-note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-eye ml-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>{t("Read In")}{`${readingYear} / ${readingMonth} / ${readingDay}`}</p>
                                            </div>
                                          </div>
                                        </h5>
                                      </div>
                                      <i className="fa-solid fa-comments text-primary text-[18px]"></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='relative flex flex-col justify-center items-center'>
                        <img loading="lazy" src={emptyImg} alt="empty img" className='w-[150px]' />
                        <p className='text-right mt-3'>{t("No Notfications")}</p>
                    </div>
                )
            }
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
export default NotficationsList;