import { Link } from "react-router-dom";
import MobileNavbar from "../../component/Nav/MobileNavbar";
import Navbar from "../../component/Nav/Navbar";
import Footer from "../../component/ui/Footer";
import ScrollUp from "../../component/ui/ScrollUp";
import SearchBar from "../../component/ui/SearchBar";
import Wrappers from "../../component/ui/Wrappers";
import "./style/style.css";
import Offcanvan from "../../component/Nav/Offcanvan";
import { useEffect } from "react";
export default function Error() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      
        <div id="page-content">
          <div id="content">
            <div className="page-wrapper">
              <div className="not-found-wrapper text-center">
                <div className="not-found-title">
                  <h1>
                    <span>404</span>
                  </h1>
                </div>
                <div className="not-found-subtitle">الصفحة غير موجودة</div>
                <div className="not-found-desc">
                  تعذر العثور على الصفحة المطلوبة. يمكن أن يكون هذا تهجئة خطأ في
                  عنوان URL أو صفحة تمت إزالتها.
                </div>
                <div className="not-found-home text-center">
                  <Link className="btn-white" to={"/"}>
                    <span className="btn-hover-effect" />
                    الرجوع الي الرئيسية
                    <i className="far fa-arrow-alt-circle-left" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
}
