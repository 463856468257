import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const getQuizeData = createAsyncThunk("lessons/show", async (url) => {
  try {
    const response = await axios.get(url, {
      withCredentials: true,
    });
    checkErrorsFunctions(response , response.data.errors);
    return response.data.data.quiz;
  } catch (error) {
    checkErrors(error.response , error.message)
  }
});

const showQuizesSlicers = createSlice({
  name: "lessons",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getQuizeData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getQuizeData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getQuizeData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default showQuizesSlicers.reducer;
