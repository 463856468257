// // // import { useEffect, useState } from 'react';
// // // import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// // // import 'react-circular-progressbar/dist/styles.css';


// // // export default function CycleGrade({studentGrade , quizequizeFinalScore}) {
// // //     const percentage = (studentGrade / quizequizeFinalScore) * 100;
// // //     return (
// // //         <div style={{ width: 70, height: 70 }}>
// // //             <CircularProgressbar
// // //                 value={percentage}
// // //                 text={`${studentGrade}% / ${percentage}%`}
// // //             />
// // //         </div>
// // //     );
// // // }

// // import { useEffect, useState } from 'react';
// // import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// // import 'react-circular-progressbar/dist/styles.css';

// // export default function CycleGrade({ studentGrade, quizequizeFinalScore }) {
// //     // Calculate the percentage of studentGrade relative to quizequizeFinalScore
// //     const percentage = (studentGrade / quizequizeFinalScore) * 100;

// //     return (
// //         <div style={{ width: 70, height: 70 }}>
// //             <CircularProgressbar
// //                 value={percentage}
// //                 text={`${studentGrade}/${quizequizeFinalScore}`}
// //                 styles={buildStyles({
// //                     // textSize: '10px',
// //                     pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
// //                     textColor: '#f88',
// //                     trailColor: '#d6d6d6',
// //                     backgroundColor: '#3e98c7',
// //                 })}
// //             />
// //         </div>
// //     );
// // }
// import { useEffect, useState } from 'react';
// import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.css';

// export default function CycleGrade({ studentGrade, quizeFinalScore }) {
//     // Calculate the percentage of studentGrade relative to quizeFinalScore
//     const percentage = (studentGrade / quizeFinalScore) * 100;

//     return (
//         <div style={{ width: 70, height: 70 }}>
//             <CircularProgressbar
//                 value={percentage}
//                 text={`${studentGrade}/${quizeFinalScore}`}
//                 styles={buildStyles({
//                     textSize: '10px',
//                     pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
//                     textColor: '#f88',
//                     trailColor: '#d6d6d6',
//                     backgroundColor: '#3e98c7',
//                 })}
//             />
//         </div>
//     );
// }

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function CycleGrade({ studentGrade, quizeFinalScore }) {
    // Calculate the percentage of studentGrade relative to quizeFinalScore
    const percentage = (studentGrade / quizeFinalScore) * 100;

    return (
        <div style={{ width: 70, height: 70 }}>
            <CircularProgressbar
                value={percentage}
                text={`${percentage.toFixed()}%`}
                styles={buildStyles({
                    // textSize: '10px',
                    pathColor: `rgba(62, 152, 199, ${percentage / 100})`,
                    textColor: percentage.toFixed() < 50 ? "red" : "",
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                })}
            />
        </div>
    );
}
