import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css"
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux"
import store from './store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import './style/quill.css';
import '@mui/material/styles';
import './i18n';
import ReactGA from "react-ga4"
ReactGA.initialize("G-3RSHF6R24H");
ReactGA.send({
  hitType : "pageview",
  page : window.location.pathname
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <React.StrictMode>
    <GoogleOAuthProvider>
      <BrowserRouter >
        <App />
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
  </Provider>
);

