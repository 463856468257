// import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function ShowingLessonHistoryTable() {
    const {t} = useTranslation();
    const {data:targetLessonData} = useSelector(
        state => state.setLessonWhichWillCompletItSlicer
    )
    useEffect(() => {
        if(document.querySelector(".MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel")) {
          document.querySelector(".MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel").innerHTML = "عدد الصفوف";
        }
        if(document.querySelector(".MuiBox-root.css-19kzrtu")) {
          document.querySelector(".MuiBox-root.css-19kzrtu").style.padding = "24px 0";
        }
      },[])
  return (
    <TableContainer component={Paper} className='mx-0'>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right">{t("Name")}</StyledTableCell>
            <StyledTableCell align="right">{t("Email")}</StyledTableCell>
            <StyledTableCell align="right">{t("Date")}</StyledTableCell>
            <StyledTableCell align="right">{t("Time")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          targetLessonData?.completed_students?.map((item) => {
            const strDate = item?.pivot.created_at;
            const date = new Date(strDate);
            const day = date.getDate();
            const month = date.getMonth() + 1; // Months are zero-based
            const year = date.getFullYear();
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;
            return (
                <StyledTableRow key={item.name}>
                <StyledTableCell component="th" scope="row" align="right">
                  {item.name}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="right">
                  {item.email}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {`${day} / ${month} / ${year}`}
                </StyledTableCell>
                <StyledTableCell align="right">
                    {`${parseInt(hours) <= 9 ? `0${hours}` : hours}:${parseInt(minutes) <= 9 ? `0${minutes}` : minutes}:${parseInt(seconds) <= 9 ? `0${seconds}` : seconds}${ampm}`}
                </StyledTableCell>
              </StyledTableRow>
            )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}
