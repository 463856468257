import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { jwtDecode } from "jwt-decode";
import { checkErrors } from "../../../global/checkResponseError";
export const registerFunc = createAsyncThunk(
    "regiter function",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data);
            console.log(response.data.errors)
            // checkErrorsFunctions(response , response.data.errors);
            console.log(response);
            if(response?.data?.data?.authorisation) {
                const token = response?.data?.data?.authorisation.token;
                const userData = response?.data?.data?.student;
                const decodeToken = jwtDecode(token);
                window.localStorage.setItem("token" , token)
                window.localStorage.setItem("expire_date", decodeToken.exp);
                window.localStorage.setItem("userData", JSON.stringify(userData));
                axios.defaults.headers.common = {'Authorization' : `Bearer ${token}`};
                axios.defaults.headers.common["Content-Type"] = "application/json";
                axios.defaults.headers.common["Accept"] = "application/json";
              }else {
                window.localStorage.removeItem("token")
              }
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message)
            return error;
        }
        
    }
)

const registerSlicer = createSlice({
    name : "regiter slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(registerFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(registerFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(registerFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default registerSlicer.reducer;
