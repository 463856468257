import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const subscribeRequestFunc = createAsyncThunk(
    "course/subscribe", 
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data , {withCredentials:true});
            checkErrorsFunctions(response , response.data.errors);
            return response.data
        }
        catch (error) {
            console.log(error)
        }
    }

);

const subscribeRequestSlicer = createSlice({
  name: "subscribe slicer",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(subscribeRequestFunc.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(subscribeRequestFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload
    });
    builder.addCase(subscribeRequestFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default subscribeRequestSlicer.reducer;
