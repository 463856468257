import { Button, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import { ControlBar, PlaybackRateMenuButton, Player } from "video-react";
import { postCompletingLessonFunc } from "../../store/slices/lessons/PostCompletingLessonSlicer";
import { useDispatch } from "react-redux";
import axios from "axios";



import Plyr from "plyr-react"
import "plyr-react/plyr.css"
import { useEffect } from "react";
const PlyrComponent = ({ url }) => {
  useEffect(() => {
    if(url) {
      const videoElement = document.querySelector('.plyr__video-wrapper video');
      const source = document.querySelector('.plyr__video-wrapper video source');
      const videoSrc = videoElement.getAttribute('src');
      // setTimeout(() => videoElement.setAttribute("src" , "") , 2000)
      videoSrc && console.log(videoSrc);
      source && console.log(source);
    }
  },[url])
    const videoOptions = {
        type: "video",
        sources: [
          {
            src: url,
            type: 'video/mp4',
            size: 720,
          },
        ],
    };
  return (
    <div className="player-wrapper rounded-md overflow-hidden">
      <Plyr
        source={videoOptions}
        controls
      />
    </div>
  );
};

function ShowVideoModal(props) {
    const dispatch = useDispatch()
    const postCompletingLessonApi = `${process.env.REACT_APP_PUBLIC_API}/api/lessons/${props.lesson_id}/complete`;
    const handleContext = (e) => {
        e.preventDefault();
    }
    const postCompletingLesson = () => {
      dispatch(postCompletingLessonFunc(postCompletingLessonApi))
    }
    return (
        <>
            <Modal
                size="lg"
                show={props.show}
                onHide={props.onHide}
                aria-labelledby="example-modal-sizes-title-lg"
                className="z-[9999999999]"
            >
                <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                   {props.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="video-content relative flex justify-center items-center  w-full h-[400px]"
                onContextMenu={handleContext}
                >
                  <PlyrComponent url={props.videoUrl} />
                    {/* <ReactPlayer
                        url={props.videoUrl}
                        controls={true}
                        width="800px"
                        height="400px"
                    /> */}
                        {/* <Player
                          autoPlay 
                          src={props.videoUrl} 
                          onTimeUpdate= {(e) => {
                          const timeWhichPlayed = e.target.currentTime;
                          const lessonDuration = e.target.duration;
                          const halfTime = lessonDuration / 2;
                          const tolerance = 1;
                          if(timeWhichPlayed >= halfTime - tolerance && timeWhichPlayed <= halfTime + tolerance) {
                            axios.post(postCompletingLessonApi , {} , {withCredentials : true});
                          }
                        }}
                      >
                        <ControlBar>
                          <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} />
                        </ControlBar>
                      </Player> */}
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn bg-primary" onClick={()=>{
                        props.onHide();
                    }}>اغلاق</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ShowVideoModal;