function ContactBox({icon , title , content}) {
    return (
        <>
            <div className="relative col-sm-12 flex flex-col justify-center items-center px-2 py-3 mb-2 border bg-white ">
                {icon}
                <h3 className="my-2">{title}</h3>
                <p className="mb-1">{content}</p>
            </div>
        </>
    )
}
export default ContactBox;