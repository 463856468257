import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllCourse = createAsyncThunk('getAllCourse',
async ()=>
{
    
    const courseData = await fetch('https://sanawy-server.vercel.app/api/courses/students',{
        method:'GET'
    }).then(response => response.json()).then(result => {return result});

 return courseData;

}
);


const coursesSlicer =  createSlice({
    name:'getAllCourses',
    initialState :{
        loading:false,
        data:null,
        error:null
    },
    extraReducers: (builder)=>{
    builder.addCase(getAllCourse.pending,(state)=>{
        state.loading = true;
        state.data = null;
        state.error = null;
        
    });
    builder.addCase(getAllCourse.fulfilled,(state,action)=>{
        if (action.payload.message === "fetched successfully") {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        }else{
            state.loading = false;
            state.data = null;
            state.error = action.payload.message;
        }
    });
    builder.addCase(getAllCourse.rejected ,(state,action)=>{
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
    })
    }

});


export default coursesSlicer.reducer;
