// import { useDispatch, useSelector } from "react-redux";
// import { useParams } from "react-router-dom"
// import { getProfileData } from "../../store/slices/users/profileSlicer";
// import { useEffect, useState } from "react";
// import PageHeader from "../../component/ui/PageHeader";
// import faildImg from "../../images/planet.png"
// import CustomLoaders from "../../component/ui/CustomLoaders";
// import "./invoices-page.css"
// import { useTranslation } from "react-i18next";

// function InvoicesPage() {
//     const {t} = useTranslation();
//     const  params = useParams();
//     const studentId = params.studentId;
//     const dispatch = useDispatch();
//     const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
//     const {
//         loading: profileLoading,
//         data: profileData,
//         error: profileError,
//       } = useSelector((s) => s.profileSlicer);
//     const [invoicesList , setInvoicesList] = useState([]);
//     const getProfile = async () => {
//         try {
//           dispatch(getProfileData(profileApi))
//         } catch (error) {
//           console.log(error);
//         }
//       };
//       useEffect(() => {
//         getProfile();
//       }, []);
//       useEffect(() => {
//         profileData && profileData.invoices && setInvoicesList(profileData.invoices)
//       },[profileData])


//       const checkPaymentStatus = (status) => {
//         if(status) {
//             switch (status) {
//                 case "rejected":
//                     return <span className=" text-[17px] flex items-center text-slate-700 py-1 px-2  rounded-sm">
//                         <i className="fa-solid fa-circle-xmark text-red-500 ml-1"></i>
//                         {t("مرفوضه")}
//                     </span>;
//                 case "confirmed":
//                     return <span className=" text-[17px] flex items-center text-slate-700 py-1 px-2  rounded-sm">
//                     <i className="fa-solid fa-circle-check text-green-500 ml-1"></i>
//                     {t("مقبولة")}
//                     </span>;
//                 case "unconfirmed":
//                     return <span className=" text-[17px] flex items-center text-slate-700 py-1 px-2 rounded-sm">
//                         <i className="fa-solid fa-hourglass-half text-orange-500 ml-1"></i>
//                         {t("قيد المراجعه")}
//                          </span>;
//                 default :
//                 console.log("no status")
//             }
//         }
//       }
//     return (
//         <>
//             <PageHeader title={"مدفوعاتي"} headerImg={"https://images.unsplash.com/photo-1609429019995-8c40f49535a5?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}/>
//             {
//                 profileLoading
//                 ?
//                 (
//                 <div className="text-center py-5">
//                     <CustomLoaders />
//                     <CustomLoaders />
//                     <CustomLoaders />
//                     <CustomLoaders />
//                 </div>
//                 )
//                 :
//                 (
//                     profileData
//                     ?
//                     (
//                         invoicesList
//                         &&
//                         invoicesList.length >= 1
//                         ?
//                         <div className="relative w-[90%] mx-auto">
//                             <div className="table-responsive payment-table my-[50px]">
//                                 <div
//                                     id="student_wrapper"
//                                     className="dataTables_wrapper no-footer"
//                                 >
//                                     <table
//                                     className="table display mb-4 dataTablesCard order-table card-table text-black application "
//                                     id="application-tbl1_next"
//                                     >
//                                     <thead>
//                                         <tr>
//                                         <th className="text-[#4d5fe3]">{t("اجمالي")}</th>
//                                         <th className="text-[#4d5fe3]">{t("خصم")}</th>
//                                         <th className="text-[#4d5fe3]">{t("نوع عملية الدفع")}</th>
//                                         <th className="text-[#4d5fe3]">{t("حالة الدفع")}</th>
//                                         <th className="text-[#4d5fe3]">{t("رقم الطلب")}</th>
//                                         <th className="text-[#4d5fe3]">{t("تاريخ العمليه")}</th>
//                                         <th className="text-[#4d5fe3] text-end">{t("الكورس")}</th>
//                                         </tr>
//                                     </thead>
//                                         <tbody>
//                                             {invoicesList &&
//                                             invoicesList.length > 0 ?
//                                             invoicesList.map((invoice, index) => {
//                                                 const creationDate = new Date(invoice.created_at)
//                                                 const creationYear = creationDate.getFullYear();
//                                                 const creationMonth = creationDate.getMonth()+1;
//                                                 const creationDay = creationDate.getDate();
//                                                 const course = invoice.course_executions[0].course
//                                                 return (
//                                                 <tr key={index}>
//                                                     <td className="font-bold text-[17px] text-[#4d5fe3]">
//                                                     {invoice.total_price}&pound;
//                                                     </td>
//                                                     <td className="text-[17px]">
//                                                         {invoice.descount}&pound;
//                                                     </td>
//                                                     <td className="text-[17px]">
//                                                         <i className="fa-solid fa-money-check-dollar ml-1 text-[#4d5fe3]"></i>
//                                                         {invoice.type_payment}
//                                                     </td>
//                                                     <td>
//                                                     {checkPaymentStatus(invoice.status_payment)}
//                                                     </td>
//                                                     <td className="text-[17px]">
//                                                     {invoice.order ? invoice.order : t("لا يوجد رقم طلب")}
//                                                     </td>
//                                                     <td className="text-[17px]">
//                                                     <i className="fa-solid fa-clock ml-1 text-[#4d5fe3]"></i>
//                                                     {`${creationYear} / ${creationMonth} / ${creationDay}`}
//                                                     </td>
//                                                     <td className="text-[17px]">
//                                                         {course.name}
//                                                     </td>
//                                                 </tr>
//                                                 );
//                                             })
//                                             :
//                                             <td colSpan={8} className="flex flex-col justify-center items-center">
//                                                 {/* <img src={noRequirmentsIcon} alt="no subscription" className="w-[50px] border-none"/> */}
//                                                 <p className="m-0 border-none">{t("لا يوجد عمليات دفع")}</p> 
//                                             </td>
//                                             }
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                         :
//                         <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
//                             <img 
//                             loading="lazy"
//                             src={faildImg}
//                             alt="faild img"
//                             className="w-[150px] mb-3"
//                             />
//                             <p>
//                             {t("لم يتم عمل اي مدفوعات")}!
//                             </p>
//                         </div>

//                     )
//                     :
//                     <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
//                         <img 
//                         loading="lazy"
//                         src={faildImg}
//                         alt="faild img"
//                         className="w-[150px] mb-3"
//                         />
//                         <p>
//                         {t("تعذر تحميل المدفوعات")}!
//                         </p>
//                     </div>
//                 )
//             }
//         </>
//     )
// }


// export default InvoicesPage

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProfileData } from "../../store/slices/users/profileSlicer";
import { useEffect, useState } from "react";
import PageHeader from "../../component/ui/PageHeader";
import faildImg from "../../images/planet.png";
import CustomLoaders from "../../component/ui/CustomLoaders";
import "./invoices-page.css";
import { useTranslation } from "react-i18next";

function InvoicesPage() {
    const { t } = useTranslation();
    const params = useParams();
    const studentId = params.studentId;
    const dispatch = useDispatch();
    const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
    const {
        loading: profileLoading,
        data: profileData,
        error: profileError,
    } = useSelector((s) => s.profileSlicer);
    const [invoicesList, setInvoicesList] = useState([]);

    const getProfile = async () => {
        try {
            dispatch(getProfileData(profileApi));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getProfile();
    }, []);

    useEffect(() => {
        profileData && profileData.invoices && setInvoicesList(profileData.invoices);
    }, [profileData]);

    const checkPaymentStatus = (status) => {
        if (status) {
            switch (status) {
                case "rejected":
                    return (
                        <span className="text-[17px] flex items-center text-slate-700 py-1 px-2 rounded-sm">
                            <i className="fa-solid fa-circle-xmark text-red-500 ml-1"></i>
                            {t("Rejected")}
                        </span>
                    );
                case "confirmed":
                    return (
                        <span className="text-[17px] flex items-center text-slate-700 py-1 px-2 rounded-sm">
                            <i className="fa-solid fa-circle-check text-green-500 ml-1"></i>
                            {t("Confirmed")}
                        </span>
                    );
                case "unconfirmed":
                    return (
                        <span className="text-[17px] flex items-center text-slate-700 py-1 px-2 rounded-sm">
                            <i className="fa-solid fa-hourglass-half text-orange-500 ml-1"></i>
                            {t("Under Review")}
                        </span>
                    );
                default:
                    console.log("no status");
            }
        }
    };

    return (
        <>
            <PageHeader
                title={t("My Payments")}
                headerImg={"https://images.unsplash.com/photo-1609429019995-8c40f49535a5?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"}
            />
            {profileLoading ? (
                <div className="text-center py-5">
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                </div>
            ) : profileData ? (
                invoicesList && invoicesList.length >= 1 ? (
                    <div className="relative w-[90%] mx-auto">
                        <div className="table-responsive payment-table my-[50px]">
                            <div id="student_wrapper" className="dataTables_wrapper no-footer">
                                <table className="table display mb-4 dataTablesCard order-table card-table text-black application" id="application-tbl1_next">
                                    <thead>
                                        <tr>
                                            <th className="">{t("Total")}</th>
                                            <th className="">{t("Discount")}</th>
                                            <th className="">{t("Payment Type")}</th>
                                            <th className="">{t("Payment Status")}</th>
                                            <th className="">{t("Order Number")}</th>
                                            <th className="">{t("Transaction Date")}</th>
                                            <th className=" text-end">{t("Course")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoicesList && invoicesList.length > 0 ? (
                                            invoicesList.map((invoice, index) => {
                                                const creationDate = new Date(invoice.created_at);
                                                const creationYear = creationDate.getFullYear();
                                                const creationMonth = creationDate.getMonth() + 1;
                                                const creationDay = creationDate.getDate();
                                                const course = invoice.course_executions[0].course;
                                                return (
                                                    <tr key={index}>
                                                        <td className="font-bold text-[17px] text-primary">
                                                            {invoice.total_price}&pound;
                                                        </td>
                                                        <td className="text-[17px]">
                                                            {invoice.descount}&pound;
                                                        </td>
                                                        <td className="text-[17px]">
                                                            <i className="fa-solid fa-money-check-dollar ml-1 text-primary"></i>
                                                            {invoice.type_payment}
                                                        </td>
                                                        <td>
                                                            {checkPaymentStatus(invoice.status_payment)}
                                                        </td>
                                                        <td className="text-[17px]">
                                                            {invoice.order ? invoice.order : t("No Order Number")}
                                                        </td>
                                                        <td className="text-[17px]">
                                                            <i className="fa-solid fa-clock ml-1 text-primary"></i>
                                                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                                        </td>
                                                        <td className="text-[17px]">
                                                            {course.name}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan={7} className="flex flex-col justify-center items-center">
                                                    <p className="m-0 border-none">{t("No Payment Transactions")}</p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                        <img loading="lazy" src={faildImg} alt={t("Failed Image")} className="w-[150px] mb-3" />
                        <p>{t("No Payments Made")}!</p>
                    </div>
                )
            ) : (
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                    <img loading="lazy" src={faildImg} alt={t("Failed Image")} className="w-[150px] mb-3" />
                    <p>{t("Failed to Load Payments")}!</p>
                </div>
            )}
        </>
    );
}

export default InvoicesPage;
