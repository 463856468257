import { Link } from "react-router-dom";

export default function Btn({title,link,classes , action}) {
  return (
    <>
      <Link to={link} type="Link" className={`inline-block px-6 py-4 font-bold text-center  text-stone-50 uppercase align-middle transition-all duration-500 cursor-pointer bg-gradient-to-tl bg-primary leading-pro ease-soft-in tracking-tight-soft shadow-soft-md  hover:scale-110 hover:rotate-2 active:opacity-85 ${classes && classes}`}
      onClick={() => {
        action && action()
      }}
      >{title}</Link>
    </>
  );
}
