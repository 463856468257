import { useEffect, useState } from "react";
import { Link, json } from "react-router-dom";
import StudentDecisions from "./StudentDescion";
import avatar_profile from "../../../images/avatar/avatar_profile.png";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../../store/slices/users/profileSlicer";
import StudentCourseCard from "./StudentCourseCard";
import UpdateUserImageForm from "./ChangeImageForm";
import PageHeader from "../../../component/ui/PageHeader";
import { editUserImageFunc } from "../../../store/slices/users/ChangeImageSlicer";
import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
import { getStudentCoursesFunc } from "../../../store/slices/courses/GetStudentCoursesSlicer";
import CustomLoaders from "../../../component/ui/CustomLoaders";
import { useTranslation } from "react-i18next";
import studentDFImage from "../../../images/graduated.png"
export default function PersonInformation() {
  const {t} = useTranslation();
  const userData = JSON.parse(localStorage.getItem("userdata"))?.data?.student;
  const [user, setUser] = useState(null);
  const [showChangeForm, setShowChangeForm] = useState();
  const [showImageForm, setShowImageForm] = useState();
  const [allExcutions, setAllExcutions] = useState([]);
  // const [studentCourses, setStudentCourses] = useState([]);
  const [subscriptions , setSubscriptions] = useState([])
  const dispatch = useDispatch();
  const {
    loading: profileLoading,
    data: profileData,
    error: profileError,
  } = useSelector((s) => s.profileSlicer);
  const {loading:getLanguageLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  // const {
  //   loading: getCoursesLoading,
  //   data: coursesData,
  //   // error: profileError,
  // } = useSelector((s) => s.getStudentCoursesSlicer);

  const [invoicesList, setInvoicesList] = useState([]);
  const [studentData, setStudentData] = useState({});
  const [showChangePass, setShowChangePass] = useState();

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;

  const refetchProfiledata = async () => {
    try {
      dispatch(getProfileData(profileApi))
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const getProfile = async () => {
      try {
        dispatch(getProfileData(profileApi))
        // dispatch(getStudentCoursesFunc())
      } catch (error) {
        console.log(error);
      }
    };
    getProfile();
  }, []);

  useEffect(() => {
    if(profileData) {
      setStudentData(profileData.student);
      // profileData && setInvoicesList(profileData);
      setSubscriptions(profileData.subscriptions)
    }
  }, [profileData]);
  // useEffect(() => {
  //   coursesData && console.log(coursesData)
  //   coursesData && setStudentCourses(coursesData)
  // },[coursesData])
  const [allExecutions , setAllExecutions] = useState([]);
  const [uniqAllExecutions , setuniqAllExecutions] = useState([]);

  // useEffect(() => {
  //   if (invoicesList && invoicesList.length >= 1) {
  //     setAllExecutions(prevCoursesList => {
  //       // Map over each invoice in the invoicesList
  //       const updatedCoursesList = invoicesList.reduce((accumulator, invoice) => {
  //         // Extract course_executions from each invoice
  //         const { course_executions } = invoice;
  //         // Concatenate the course_executions to the accumulator
  //         return [...accumulator, ...course_executions];
  //       }, prevCoursesList); // Start with the previous state of coursesList
  
  //       return updatedCoursesList; // Set the state to the updated coursesList
  //     });
  //   }
  // }, [invoicesList]);
  

  // useEffect(() => {
  //   const uniq = new Set();
  //   allExecutions.map((ex) => {
  //     uniq.add(JSON.stringify(ex))
  //   })
  //   const uniqExecutionsObjects = Array.from(uniq).map(ex => JSON.parse(ex));
  //   setuniqAllExecutions(uniqExecutionsObjects)
  // },[allExecutions])

  useEffect(() => {
    console.log(invoicesList)
  },[invoicesList])

  const checkStudentStatus = (status) => {
    if(status) {
      switch (status) {
        case "publish" :
          return <span className="relative rounded-md bg-green-500 text-white py-1 px-2 text-sm">نشط</span>
        case "un_publish" :
          return <span className="relative rounded-md bg-red-500 text-white py-1 px-2 text-sm">غير نشط</span>
        default :
          console.log("no status")
      }
    }
  }


  // return (
  //   <>
  //   {
  //     profileLoading
  //     ?
  //     <div className="text-center py-1 h-[100vh] flex justify-center items-center">
  //       <CustomLoaders />
  //       <CustomLoaders />
  //       <CustomLoaders />
  //       <CustomLoaders />
  //     </div>
  //     :
  //     profileData
  //     ?
  //     (
  //       studentData && Object.keys(studentData).length >= 1
  //       &&
  //       <div className="page-content relative w-full py-5">
  //         <div className="relative w-[90%] mx-auto flex flex-col">
  //           <div className="user-images relative w-full mb-3">
  //             <div className="relative w-full h-[350px] user-cover rounded-md overflow-hidden">
  //               <img src={"https://images.unsplash.com/photo-1704098623946-2f273eeeead2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="cover img" className="absolute top-0 left-0 w-full h-full"/>
  //             </div>
  //             <div className="relative">
  //               <div className={`personal-image absolute bottom-[-50px] rounded-[50%] overflow-hidden w-[100px] h-[100px] border-[7px] border-white ${languageData?.defaultLang?.slug !== "ar" ? "left-[30px]" : "right-[30px]"}`}>
  //                 <img src={studentData.image_url ? studentData.image_url : "https://images.unsplash.com/photo-1704098623946-2f273eeeead2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="img" className="absolute top-0 left-0 w-full h-full"/>
  //               </div>
  //               <div className={`w-fit z-[2] absolute ${languageData?.defaultLang?.slug !== "ar" ? "left-[23px]" : "right-[23px]"} bottom-[-40px]`}>
  //                 <label className="relative rounded-[50%] bg-slate-700 text-white cursor-pointer w-fit p-2 flex justify-center items-center"
  //                   onClick={() => setShowImageForm(true)}
  //                 >
  //                   <i class="fa-solid fa-camera"></i>
  //                 </label>
  //               </div>
  //             </div>
  //             </div>
  //           <div className="user-data relative mb-2 mt-[30px] py-4">
  //             <div className="relative data-content flex flex-col">
  //               <div className="relative flex items-center mt-2">
  //                 <i className="fa-brands fa-cuttlefish mx-1"></i>
  //                 <h5>{t("الاسم")} : {studentData.name}</h5>
  //               </div>
  //               <div className="relative flex items-center mt-2">
  //                 <i className="fa-solid fa-envelope mx-1"></i>
  //                 <h5>{t("البريد الالكتروني ")}: {studentData.email}</h5>
  //               </div>
  //               <div className="relative flex items-center mt-2">
  //                 <i className="fa-solid fa-square-phone-flip mx-1"></i>
  //                 <h5>{t("رقم الموبايل ")}: {studentData.phone}</h5>
  //               </div>
  //               <div className="relative flex items-center mt-2">
  //                 <i className="fa-solid fa-signal mx-1"></i>
  //                 <h5>{t("حالة الطالب ")}: {checkStudentStatus(studentData.publish)}</h5>
  //               </div>
  //               <div className="relative flex items-center gap-2 mt-4">
  //                 <Link 
  //                 className="btn w-fit bg-[#153776] text-white flex items-center" 
  //                 to="#"
  //                 onClick={() => {
  //                   setShowChangePass(true)
  //                 }}
  //                 >
  //                 <i className="fa-solid fa-key mx-1"></i>
  //                 {t("تغيير كمة المرور")}
  //                 </Link>
  //                 <Link className="btn w-fit bg-primary text-white flex items-center" to={`invoices/${studentData.id}`}>
  //                 <i className="fa-solid fa-money-check-dollar mx-1"></i>
  //                 {t("عمليات الدفع")}
  //                 </Link>
  //               </div>
  //             </div>
  //           </div>
  //           <h1 className="font-bold text-xl my-3 relative px-4 py-2">{t("الاشتراكات")} <span className={`absolute top-0 ${languageData?.defaultLang?.slug !== "ar" ? "left-0" : "right-0"} w-2 h-full bg-primary rounded-[10px]`}></span></h1>
  //           <div className="courses-content relative row">
  //           {profileData && subscriptions && subscriptions.length > 0 ? (
  //               <>
  //                 {
  //                 subscriptions.map((subscripe, index) => {
  //                   const {
  //                     course_execution,
  //                     total_price , descount  , course_execution_id
  //                   } = subscripe
  //                   const {title , start_date_student , end_date_student ,  date_student_status , course:studentCourse} = course_execution
  //                   return (
  //                   <StudentCourseCard
  //                     key={index}
  //                     index={index}
  //                     courseDiscount = {descount}
  //                     courseId={studentCourse.id}
  //                     courseImg={studentCourse.image_url}
  //                     total_price = {total_price}
  //                     courseName={studentCourse.name}
  //                     exName={title}
  //                     startDate={start_date_student}
  //                     endDate={end_date_student}
  //                     loading = {profileLoading}
  //                     teacherName={studentCourse.user && studentCourse.user.name}
  //                     teacherImg={studentCourse.user && studentCourse.user.image_url}
  //                     courseStatus={date_student_status}
  //                     courseExId = {course_execution_id}
  //                   />
  //                   )
  //                 }
  //                 )}
  //               </>
  //             ) : (
  //               <>
  //                 <div className="alert alert-primary">
  //                   {t("لا يوجد كورسات بعد ")}.
  //                 </div>
  //               </>
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     )
  //     :
  //     <h2>{t("لا يوجد معلومات لهذا الطالب!")}</h2>
  //   }
  //     <UpdateUserImageForm
  //         show={showImageForm}
  //         onHide={() => setShowImageForm(false)} 
  //         refetch_data= {() => refetchProfiledata()}
  //     />
  //     <SendEmailModal
  //         show={showChangePass}
  //         onHide={() => setShowChangePass(false)} 
  //     />
  //   </>
  // );
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const [img , setImage] = useState(null)
  useEffect(() => {
    data && setImage(data?.site_banner_header);
  },[data])
  return (
    <>
      {
        profileLoading
        ?
        <div className="text-center py-1 h-[100vh] flex justify-center items-center">
          <CustomLoaders />
          <CustomLoaders />
          <CustomLoaders />
          <CustomLoaders />
        </div>
        :
        profileData
        ?
        (
          studentData && Object.keys(studentData).length >= 1
          &&
          <div className="page-content relative w-full py-5">
            <div className="relative w-[90%] mx-auto flex flex-col">
              <div className="user-images relative w-full mb-3">
                <div className="relative w-full h-[350px] user-cover rounded-md overflow-hidden">
                  <img src={img} alt="cover img" className="absolute top-0 left-0 w-full h-full object-cover"/>
                  <span className="absolute top-0 w-full h-full bg-black opacity-20"></span>
                </div>
                <div className="relative">
                  <div className={`personal-image absolute bottom-[-50px] rounded-[50%] overflow-hidden w-[100px] h-[100px] border-[7px] border-white ${languageData?.defaultLang?.slug !== "ar" ? "left-[30px]" : "right-[30px]"} bg-white`}>
                    <img src={studentData.image_url ? studentData.image_url : studentDFImage} alt="img" className="absolute top-0 left-0 w-full h-full"/>
                  </div>
                  <div className={`w-fit z-[2] absolute ${languageData?.defaultLang?.slug !== "ar" ? "left-[23px]" : "right-[23px]"} bottom-[-40px]`}>
                    <label className="relative rounded-[50%] bg-slate-700 text-white cursor-pointer w-fit p-2 flex justify-center items-center"
                      onClick={() => setShowImageForm(true)}
                    >
                      <i className="fa-solid fa-camera"></i>
                    </label>
                  </div>
                </div>
                </div>
              <div className="user-data relative mb-2 mt-[30px] py-4">
                <div className="relative data-content flex flex-col">
                  <div className="relative flex items-center mt-2">
                    <i className="fa-brands fa-cuttlefish mx-1"></i>
                    <h5>{t("Name")}: {studentData.name}</h5>
                  </div>
                  <div className="relative flex items-center mt-2">
                    <i className="fa-solid fa-envelope mx-1"></i>
                    <h5>{t("Email")}: {studentData.email}</h5>
                  </div>
                  <div className="relative flex items-center mt-2">
                    <i className="fa-solid fa-square-phone-flip mx-1"></i>
                    <h5>{t("Phone Number")}: {studentData.phone}</h5>
                  </div>
                  <div className="relative flex items-center mt-2">
                    <i className="fa-solid fa-signal mx-1"></i>
                    <h5>{t("Student Status")}: {checkStudentStatus(studentData.publish)}</h5>
                  </div>
                  <div className="relative flex items-center gap-2 mt-4">
                    <Link 
                    className="btn w-fit bg-[#153776] text-white flex items-center" 
                    to="#"
                    onClick={() => {
                      setShowChangePass(true)
                    }}
                    >
                    <i className="fa-solid fa-key mx-1"></i>
                    {t("Change Password")}
                    </Link>
                    {/* <Link className="btn w-fit bg-primary text-white flex items-center" to={`invoices/${studentData.id}`}>
                    <i className="fa-solid fa-money-check-dollar mx-1"></i>
                    {t("Payment Transactions")}
                    </Link> */}
                  </div>
                </div>
              </div>
              <h1 className="font-bold text-xl my-3 relative px-4 py-2">{t("Subscriptions")} <span className={`absolute top-0 ${languageData?.defaultLang?.slug !== "ar" ? "left-0" : "right-0"} w-2 h-full bg-primary rounded-[10px]`}></span></h1>
              <div className="courses-content relative row">
                {profileData && subscriptions && subscriptions.length > 0 ? (
                  <>
                    {
                    subscriptions.map((subscription, index) => {
                      const {
                        course_execution,
                        total_price,
                        discount,
                        course_execution_id
                      } = subscription;
                      const {title, start_date_student, end_date_student, date_student_status, course: studentCourse} = course_execution;
                      return (
                        <StudentCourseCard
                          key={index}
                          index={index}
                          courseDiscount={discount}
                          courseId={studentCourse.id}
                          courseImg={studentCourse.image_url}
                          total_price={total_price}
                          courseName={studentCourse.name}
                          exName={title}
                          startDate={start_date_student}
                          endDate={end_date_student}
                          loading={profileLoading}
                          teacherName={studentCourse.user && studentCourse.user.name}
                          teacherImg={studentCourse.user && studentCourse.user.image_url}
                          courseStatus={date_student_status}
                          courseExId={course_execution_id}
                        />
                      )
                    })}
                  </>
                ) : (
                  <div className="alert alert-primary">
                    {t("No courses available")}
                  </div>
                )}
              </div>
            </div>
          </div>
        )
        :
        <h2>{t("No information available for this student")}</h2>
      }
      <UpdateUserImageForm
        show={showImageForm}
        onHide={() => setShowImageForm(false)} 
        refetch_data={() => refetchProfiledata()}
      />
      <SendEmailModal
        show={showChangePass}
        onHide={() => setShowChangePass(false)} 
      />
    </>
  );
  

}
