import { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';


export default function CustomTimer({fullTimeInMinutes , exitQuize}) {
    const {t} = useTranslation();
    // const fullTimeInMinutes = 1;
    const fullTimeInSeconds = fullTimeInMinutes * 60;
    const [timeLeft, setTimeLeft] = useState(fullTimeInSeconds);
    const [percentage, setPercentage] = useState(100);

    // useEffect(() => {
    //     if (timeLeft > 0) {
    //         const myInterval = setInterval(() => {
    //             setTimeLeft((prev) => prev - 1);
    //         }, 1000);
    //         if(timeLeft <= 40) {
    //             exitQuize && exitQuize();
    //             return () => clearInterval(myInterval);
    //         }
    //     }
    // }, [timeLeft]);
    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime === 14) {
                    clearInterval(timer);
                    // exitQuize(); // Exit quiz when time is up
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);
        return () => clearInterval(timer); // Clear interval on component unmount
    }, []);
    useEffect(() => {
        if (timeLeft === 15) {
            exitQuize();
        }
    },[timeLeft])

    useEffect(() => {
        setPercentage((timeLeft / fullTimeInSeconds) * 100);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div style={{ width: 70, height: 70 }}>
            <CircularProgressbar
                value={percentage}
                text={timeLeft >= 15 ? formatTime(timeLeft) : t("Quize End")}
                styles={buildStyles({
                    textColor: "black",
                    pathColor: (percentage >= 50)  ? "green" : (percentage < 50 &&  percentage >= 25 ? "orange" : "red"),
                    trailColor: "#d6d6d6",
                })}
            />
        </div>
    );
}
