import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";

export const contactApis = createAsyncThunk("contact/create", async (data) => {
  const response = await axios.post(`${process.env.REACT_APP_PUBLIC_API}/api/contacts` ,{
        name: data.name,
        email: data.email,
        subject: data.subject,
        phone: data.phone,
        message: data.message,
      } , 
      {withCredentials : true})
      checkErrorsFunctions(response , response.data.errors);
      return response.data
});

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    loading: false,
    responce: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(contactApis.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(contactApis.fulfilled, (state, action) => {
      state.loading = false;
      state.responce = action.payload
    });
    builder.addCase(contactApis.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default contactSlice.reducer;
