// import { Link, useNavigate, useParams } from "react-router-dom"
// import trueImg from "../../images/checkmark-3d-icon-accept-symbol-vector-eps-10_574175-699.png"
// import approveImg from "../../images/approved-status-document-check-mark-icon-confirm-finished-test-graphic-illustrated-done-completed_212005-737.avif"
// import folderImg from "../../images/unique-3d-realistic-letter-check-mark-confirmation-open-envelope-new-concept-vector-design_135323-1019-removebg-preview.png"
// import { Button } from "@mui/material";
// import DuoIcon from '@mui/icons-material/Duo';
// import HomeIcon from '@mui/icons-material/Home';
// import {useState , useEffect} from "react";
// export default function SuccessActivationCourse() {
//     const {executionId} = useParams();
//     const navigate = useNavigate();
//     const [mounted , setMounted] = useState(false);
//     useEffect(() => {
//         setMounted(true);
//         return () => setMounted(false)
//     },[])
//     return (
//         <>
//             <div className="page-content relative min-h-screen flex justify-center items-center">
//                 <div className="container relative flex justify-center items-center ">
//                     <div className="success-content relative flex flex-col justify-center items-center">
//                         <div className="images relative mb-3 bg">
//                             <img src={trueImg} alt="img"  className={`w-[100px] transition-all duration-500 opacity-60 absolute top-0 right-0 ${mounted ? "translate-x-0 opacity-100" : "translate-x-[-10px] opacity-0"}`}/>

//                             <img src={approveImg} alt="img"  className={`w-[300px] transition-all duration-500 ${mounted ? "translate-x-0 opacity-100" : "translate-x-[-10px] opacity-0"}`}/>
//                             {/* <img src={folderImg} alt="img"  className="w-[200px]"/> */}
//                         </div>
//                         <h2 className="relative my-3">تم التفعيل بنجاح!</h2>
//                         <div className="links relative flex items-center">
//                             {/* <Link to={`/lessons/${executionId}`} className="mx-1">مشاهده الكورس</Link> */}
//                             <Button variant="outlined" onClick={() => navigate(`/lessons/${executionId}`)} className="mx-1">
//                                 مشاهدة الكورس
//                                 <DuoIcon/>
//                             </Button>
//                             <Link to={`/`} className="mx-1 text-sm"> 
//                                 العودة للرئيسية
//                                 <HomeIcon/>
//                             </Link>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }
import { Link, useNavigate, useParams } from "react-router-dom";
import trueImg from "../../images/checkmark-3d-icon-accept-symbol-vector-eps-10_574175-699.png";
import approveImg from "../../images/approved-status-document-check-mark-icon-confirm-finished-test-graphic-illustrated-done-completed_212005-737.avif";
import { Button } from "@mui/material";
import DuoIcon from '@mui/icons-material/Duo';
import HomeIcon from '@mui/icons-material/Home';
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function SuccessActivationCourse() {
    const {t} = useTranslation();
    const { executionId } = useParams();
    const navigate = useNavigate();
    const [mounted, setMounted] = useState(false); // Initialize as false

    useEffect(() => {
        setMounted(true); // Set mounted to true when the component mounts
        return () => setMounted(false); // Cleanup to set mounted to false when the component unmounts
    }, []);

    return (
        <>
            <div className="page-content relative min-h-screen flex justify-center items-center">
                <div className="container relative flex justify-center items-center">
                    <div className="success-content relative flex flex-col justify-center items-center">
                        <div className="images relative mb-3 bg">
                            <img
                                src={trueImg}
                                alt="img"
                                className={`w-[100px] transition-all duration-500 absolute top-0 right-0  ${mounted ? "scale-1 opacity-100" : "scale-0 opacity-0"}`}
                            />
                            <img
                                src={approveImg}
                                alt="img"
                                className={`w-[300px] transition-all duration-500`}
                            />
                            <img
                                src={trueImg}
                                alt="img"
                                className={`w-[100px] transition-all duration-500 absolute bottom-0 left-0  ${mounted ? "scale-1 opacity-100" : "scale-0 opacity-0"}`}
                            />
                            {/* <img src={folderImg} alt="img"  className="w-[200px]"/> */}
                        </div>
                        <h2 className="relative my-3">{t("تم التفعيل بنجاح")}</h2>
                        <div className="links relative flex items-center">
                            {/* <Link to={`/lessons/${executionId}`} className="mx-1">مشاهده الكورس</Link> */}
                            <Button variant="outlined" onClick={() => navigate(`/lessons/${executionId}`)} className="mx-1">
                                {t("مشاهدة الكورس")}
                                <DuoIcon />
                            </Button>
                            <Link to={`/`} className="mx-1 text-sm">
                                {t("العودة للرئيسية")}
                                <HomeIcon />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
