import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom"
import { getSingleTeacherFunc } from "../../../../../store/slices/teachers/GetSingleTeacherSlicer";
import { useContext, useEffect, useState } from "react";
import PageHeader from "../../../../../component/ui/PageHeader";
import failedImg from "../../../../../images/blank-sheet-with-magnifying-glass-icon-3d-rendering-3d-render-cartoon-icon-style-concept_457716-1702-removebg-preview.png"
import CustomLoaders from "../../../../../component/ui/CustomLoaders";
import { useTranslation } from "react-i18next";
import { fetchAllCoursesFunc } from "../../../../../store/slices/courses/coursesSlicer";
import faildImg from "../../../../../images/planet.png";
import ContextApi from "../../../../../store/slices/Context";
import dfCourseImg from "../../../../../images/df-course-img.webp";
export default function TeacherProfile() {
    const {t} = useTranslation();
    const {teacherId} = useParams();
    const contextApi = useContext(ContextApi);
    const site_global_currency = contextApi?.site_global_currency;
    const singleTeacherApi = `${process.env.REACT_APP_PUBLIC_API}/api/teachers/${teacherId}`;
    const executionsApi = `${process.env.REACT_APP_PUBLIC_API}/api/course-executions?teacher_id=${teacherId}`;
    const dispatch = useDispatch();
    const {loading , data:teacherData} = useSelector(
        state => state.getSingleTeacherSlicer
    )
    const { loading: getExecutionsLoading, data: executionsData } = useSelector(
        (s) => s.GetAllCoursesSlicer
    );
    const [teacher , setTeacher] = useState({});
    const [executionsList, setExecutionsList] = useState([]);
    const getData = () => {
        dispatch(getSingleTeacherFunc(singleTeacherApi))
    }
    const getExecutions = () => {
        dispatch(fetchAllCoursesFunc(executionsApi))
        .then((result) => {
            console.log(result)
            setExecutionsList(result?.payload)
        })
    }
    useEffect(() => {
        getData();
        getExecutions();
    },[])
    useEffect(() => {
        teacherData && setTeacher(teacherData)
    },[teacherData])
    // useEffect(() => {
    //   if (executionsData && executionsData.length >= 1) {
    //     setExecutionsList(executionsData);
    //     console.log(executionsData);
    //   }
    // }, [executionsData]);
    const maxRate = 5
    const calcRate = (rateCount) => {
      if(rateCount) {
          for(let i=0 ; i <= parseInt(rateCount); i++) {
              const stars = [];
              for (let i = 0; i < parseInt(rateCount); i++) {
                  stars.push(
                    <i className="fa-solid fa-star text-warning"></i>
                  );
              }
              for (let i = 0; i < parseInt(maxRate - rateCount); i++) {
                  stars.push(
                    <i className="fa-regular fa-star text-warning"></i>
                  );
              }
              return (
                <span className="d-flex align-items-center">
                    {stars}
                    {/* <p className="text-dark m-0" style={{transform: "translateY(1px)translateX(2px)"}}>0.{rateCount}</p> */}
                </span>
              );
          }
      }
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <PageHeader title="Instructor Profile"/> 
            {
                loading
                ?
                <div className="text-center py-1 h-[100vh] flex justify-center items-center">
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                </div>
                :
                teacherData
                ?
                (
                    <div className="relative py-[100px]">
                        <div className="container relative  p-[20px] border shadow-md shadow-slate-100 rounded-xl">
                            <div className="row relative">
                                <div className="col-sm-12 col-md-4">
                                    <div className="image-div w-[200px] md:w-[270px] h-[200px] md:h-[340px] relative rounded-[20px] overflow-hidden">
                                        <img src={teacher?.image_url ? teacher?.image_url : ""} alt="img" className="absolute top-0 left-0 w-full h-full transition-all duration-500 hover:scale-110" />
                                    </div>
                                </div>
                                <div className="relative col-sm-12 col-md-8 p-3">
                                    <div className="relative flex flex-col border-b border-[#00a4e5] py-[15px]">
                                        <div className="flex flex-col">
                                            <span className="text-sm text-[#00a4e5]">{t("INSTRUCTOR PROFILE")}</span>
                                            <h2 className="my-[10px] text-[40px] font-bold text-slate-800">{teacher?.name}</h2>
                                        </div>
                                        <div className="relative ">
                                            <p>{teacher?.baio}</p>
                                        </div>
                                    </div>
                                        <>
                                            {
                                            executionsData && executionsList && executionsList.length >= 1 
                                            ? 
                                            (
                                                <div className="row mt-3">
                                                    <div className="my-3">
                                                        <h2 className="font-bold text-[#00a4e5]">{t('Courses')}</h2>
                                                    </div>
                                                    {executionsList.map((excution, index) => {
                                                    const {course , discount , date_student_status} = excution
                                                    return (
                                                        <div key={index} className="relative transition-all duration-500 hover:translate-y-[-4px] col-sm-12 col-md-6 p-2 rounded-lg shadow-md shadow-slate-200">
                                                            <div className="relative w-full h-[250px] mb-2">
                                                            <img loading="lazy"
                                                                src={course?.image_url !== null ? course?.image_url : dfCourseImg}
                                                                alt={t("Course image")}
                                                                className="absolute top-0 left-0 w-full h-full"
                                                            />
                                                            </div>
                                                            <h2 className="my-2">{course?.name}</h2>
                                                            <div className="flex items-center">
                                                                {calcRate(course?.rating)} {course?.rating}
                                                            </div>
                                                            {discount ? (
                                                                <div className="relative flex flex-col my-2">
                                                                <p className="relative text-sm">
                                                                    {t("Price")}: 
                                                                    <span className="font-bold">{site_global_currency} {parseInt(excution.price) - parseInt(discount)}</span>
                                                                    <del className="text-red-500 mx-2">{excution.price} {site_global_currency}</del>
                                                                </p>
                                                                </div>
                                                            ) : (
                                                                <p className="courses-one__single-content-price py-2 text-slate-600">
                                                                {t("Price")}: {excution.price} {site_global_currency === "EGP" ? "EGP" : site_global_currency}
                                                                </p>
                                                            )}
                                                            {/* <p className="mt-1">{date_student_status}</p> */}
                                                            <Link className="absolute top-0 left-0 w-full h-full" to={`/courses/${excution?.id}`}></Link>
                                                        </div>
                                                    )
                                                    })}
                                                </div>
                                            )
                                            :
                                            <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                            <img
                                                loading="lazy"
                                                src={faildImg}
                                                alt={t("Failed Image")}
                                                className="w-[150px] mb-3"
                                            />
                                            <p>
                                                {t("No Courses Available")}!
                                            </p>
                                            </div>
                                            }
                                        </>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                    <img
                    loading="lazy"
                    src={failedImg}
                    alt="failed img"
                    className="w-[150px] mb-3"
                    />
                    <p className="">
                    {t("No Data available yet")}!
                    </p>
                </div>
            }
        </>
    )
}