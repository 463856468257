import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../component/ui/PageHeader";
import imageHeader from "../../../images/my-courses/pexels-pixabay-247899.jpg";
import faildImg from "../../../images/planet.png";
import { useEffect, useState } from "react";
import { getProfileData } from "../../../store/slices/users/profileSlicer";
import CustomLoaders from "../../../component/ui/CustomLoaders";
import StudentCourseCard from "../../Profile/component/StudentCourseCard";
import "./my-courses.css";
import { Link } from "react-router-dom";
function MyCoursesPage() {
    const dispatch = useDispatch();
    const [userExecutions , setUserExecutions] = useState([]);
    const [userCourses , setUserCourses] = useState([]);
    const {
      loading: profileLoading,
      data: profileData,
      error: profileError,
    } = useSelector((s) => s.profileSlicer);
    useEffect(() => {
      const getProfile = async () => {
        try {
          dispatch(getProfileData("https://apis.alawa2el.com/api/profile"))
        } catch (error) {
          console.log(error);
        }
      };
      getProfile();
    }, []);
  
    useEffect(() => {
      profileData && profileData.executions && setUserExecutions(profileData.executions);
    //   profileData && setUserCourses(profileData.courses);
      console.log(profileData.executions)
    }, [profileData]);
    return (
        <>
        <PageHeader title={"كورساتي"}  headerImg={imageHeader}/>
        {
            profileLoading
            ?
            <>
                <div className="row my-[100px]">
                <div className="text-center py-1">
                    <CustomLoaders />
                    <CustomLoaders />
                    <CustomLoaders />
                </div>
                </div>
            </>
            :
            (
                profileData && userExecutions && userExecutions.length >= 1
                ?
                <div className="relative my-courses-page-content flex flex-col justify-center items-center mt-3 py-[20px]">
                    <div className="page-container  relative w-[90%] mx-auto flex justify-between items-start">
                        <div className="courses-content  relative flex flex-col md:flex-row flex-wrap">
                            {
                                profileData && userExecutions &&
                                userExecutions.map((execution , index) => {
                                    const {
                                        price , 
                                        discount_price,
                                        start_date_student , 
                                        end_date_student , 
                                        date_student_status,
                                    } = execution
                                    const {course} = execution;
                                    return (
                                        <StudentCourseCard
                                            courseStatus={date_student_status}
                                            key={course.id}
                                            index={index}
                                            price = {price}
                                            courseDiscount = {discount_price}
                                            courseId={course.id}
                                            courseImg={course.image_url}
                                            courseName={course.name}
                                            startDate={start_date_student}
                                            endDate={end_date_student}
                                            loading = {profileLoading}
                                            teacherName={course.user && course.user.name}
                                            teacherImg={course.user && course.user.image_url}
                                            customClasses={"shadow-md shadow-slate-300 m-0 w-full md:w-[250px]"}
                                        />
                                    )
                                })
                            }
                        </div>
                        {/* <div className="filter-sidel relative flex flex-xol w-full md:w-[25%] bg-white rounded-md shadow-md shadow-slate-300">
                            sca
                        </div> */}
                    </div>
                    <Link to="/courses" className="btn btn-primary w-fit relative my-4">الاشتراك في المزيد</Link>
                </div>
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                <img
                  src={faildImg}
                  alt="faild img"
                  className="w-[150px] mb-3"
                />
                <p>
                  لا يوجد كورسات!
                </p>
                </div>
            )
        }
        </>
    )
}

export default MyCoursesPage;