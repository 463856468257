import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const showStreamLessonFunc = createAsyncThunk("lessons/show/single/stream", async ({url , lessonType}) => {
  try {
    const response = await axios.get(url , {responseType : (lessonType === "pdf" || lessonType === "pdf") && "blob"});

      checkErrorsFunctions(response , response.data.errors);
        return response
    } catch (error) {
        checkErrors(error.response , error.message)
    }
});

const showStreamLessonSlicer = createSlice({
  name: "lessons/show/single/stream",
  initialState: {
    loading: false,
    data: {},
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(showStreamLessonFunc.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(showStreamLessonFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(showStreamLessonFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});

export default showStreamLessonSlicer.reducer;
