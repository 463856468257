import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const showLessonQuizeFunc = createAsyncThunk("lessons/show/single/quize", async (url) => {
  try {
        const response = await axios.get(url);
        checkErrorsFunctions(response , response.data.errors);
        return response.data.data
    } catch (error) {
        checkErrors(error.response , error.message);
        return error
    }
});

const showLessonQuizeSlicer = createSlice({
  name: "lessons/show/single/quize",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers : {
    clearQuizeData : (state , action) => {
      state.data = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(showLessonQuizeFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(showLessonQuizeFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(showLessonQuizeFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.data = action.payload
    });
  },
});
export const {clearQuizeData} = showLessonQuizeSlicer.actions
export default showLessonQuizeSlicer.reducer;
