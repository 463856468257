// import { Modal , Button } from "react-bootstrap";
// import { useSelector } from "react-redux";
// import { useDispatch } from "react-redux";
// import { useState , useEffect } from "react";
// import { editUserImageFunc } from "../../../store/slices/users/ChangeImageSlicer";
// import OverLayLoading from "../../../component/global/overlay-loading/OverLayLoading";
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// function UpdateUserImageForm(props) {
//     const {t} = useTranslation()
//     const {refetch_data} = props
//     const { editImageLoading} = useSelector((state) => state.editUserImageSlicer);
//     const dispatch = useDispatch();
//     const [newImage , setNewImage] = useState("");
//     const updateimageApi = `${process.env.REACT_APP_PUBLIC_API}/api/student/upload-image`

//     const updateImage = (e) => {
//       e.preventDefault()
//       if(newImage) {
//         const imageForm = new FormData();
//         imageForm.append("image" , newImage)
//         dispatch(editUserImageFunc({api : updateimageApi , image : imageForm}))
//         .then(result => {
//           console.log(result)
//           if(result.payload.success === true) {
//             toast.success(result?.payload?.message, {
//               position: "top-right",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//           });
//             refetch_data();
//             props.onHide();
//             setNewImage(null);
//           }
//         })
//       }
//     }
  
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center justify-end  w-full text-[17px]">
//           {t("تحديث الصوره الشخصية")}
//           <i class="fa-solid fa-camera ml-1"></i>
//         </Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//       <div className="basic-form">
//               <form 
//                         onSubmit={(e) => {
//                           updateImage(e);
//                         }}
//                         id="send-recipct-form" 
//                         class="file-upload-form activation-form mt-3 flex items-center justify-center flex-col">
//                         <label for="file" class="file-upload-label border-dashed border-slate-400 cursor-pointer">
//                             <div class="file-upload-design flex flex-col items-center justify-center gap-2">
//                             <svg viewBox="0 0 640 512" class="h-10 text-gray-500 fill-current">
//                                 <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
//                             </svg>
//                             {
//                                 newImage &&
//                                 newImage !== "" &&
//                                 newImage.name
//                                 ?
//                                 newImage.name
//                                 :
//                                 <p>{t("قم بتحميل صوره ")}({t("حجم")} 100 * 100)</p>
//                             }
//                             <span class="browse-button bg-gray-700 px-4 py-2 rounded-lg text-white transition duration-300 hover:bg-gray-900">تحميل صوره</span>
//                             </div>
//                             <input id="file" type="file" className="hidden" accept='image/*'
//                                 onChange={(e) => {
//                                   setNewImage(e.target.files[0])
//                                 }}
//                             />
//                         </label>
//                         {
//                             newImage !== ""
//                             &&
//                             <button 
//                             type='submit' 
//                             form='send-recipct-form' 
//                             class={`relative group cursor-pointer text-sky-50  overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${editImageLoading ? "pointer-events-none opacity-50" : ""}`}>
//                                 <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
//                                 <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
//                                 <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
//                                 <div class="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
//                                 <p class="z-10">{t("ارسال الان")}</p>
//                             </button>
//                         }
//                         </form>
//               </div>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button className="bg-slate-700 outline-none" onClick={()=>{
//           props.onHide();
//         }}>{t("الغاء")}</Button>
//       </Modal.Footer>
//       <OverLayLoading loading={editImageLoading} loadingtext={t("جاري تحديث الصوره..")}/>
//     </Modal>
//   );
//   }

//   export default UpdateUserImageForm;

import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { editUserImageFunc } from "../../../store/slices/users/ChangeImageSlicer";
import OverLayLoading from "../../../component/global/overlay-loading/OverLayLoading";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

function UpdateUserImageForm(props) {
    const { t } = useTranslation();
    const { refetch_data } = props;
    const { editImageLoading } = useSelector((state) => state.editUserImageSlicer);
    const dispatch = useDispatch();
    const [newImage, setNewImage] = useState("");
    const updateimageApi = `${process.env.REACT_APP_PUBLIC_API}/api/student/upload-image`;

    const updateImage = (e) => {
        e.preventDefault();
        if (newImage) {
            const imageForm = new FormData();
            imageForm.append("image", newImage);
            dispatch(editUserImageFunc({ api: updateimageApi, image: imageForm }))
                .then(result => {
                    console.log(result);
                    if (result.payload.success === true) {
                        toast.success(result?.payload?.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        refetch_data();
                        props.onHide();
                        setNewImage(null);
                    }
                });
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center justify-end w-full text-[17px]">
                    {t("Update Profile Picture")}
                    <i className="fa-solid fa-camera ml-1"></i>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="basic-form">
                    <form
                        onSubmit={(e) => {
                            updateImage(e);
                        }}
                        id="send-recipct-form"
                        className="file-upload-form activation-form mt-3 flex items-center justify-center flex-col"
                    >
                        <label htmlFor="file" className="file-upload-label border-dashed border-slate-400 cursor-pointer">
                            <div className="file-upload-design flex flex-col items-center justify-center gap-2">
                                <svg viewBox="0 0 640 512" className="h-10 text-gray-500 fill-current">
                                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                                </svg>
                                {
                                    newImage &&
                                    newImage !== "" &&
                                    newImage.name
                                        ?
                                        newImage.name
                                        :
                                        <p>{t("Upload a Picture")} ({t("Size")} 100 * 100)</p>
                                }
                                <span className="browse-button bg-gray-700 px-4 py-2 rounded-lg text-white transition duration-300 hover:bg-gray-900">{t("Upload Picture")}</span>
                            </div>
                            <input id="file" type="file" className="hidden" accept='image/*'
                                onChange={(e) => {
                                    setNewImage(e.target.files[0]);
                                }}
                            />
                        </label>
                        {
                            newImage !== "" &&
                            <button
                                type='submit'
                                form='send-recipct-form'
                                className={`relative group cursor-pointer text-sky-50 overflow-hidden h-13 w-56 my-3 rounded-md bg-sky-800 p-2 flex justify-center items-center font-extrabold ${editImageLoading ? "pointer-events-none opacity-50" : ""}`}
                            >
                                <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-40 h-40 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-900"></div>
                                <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-32 h-32 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-800"></div>
                                <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-24 h-24 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-700"></div>
                                <div className="absolute top-3 right-20 group-hover:top-12 group-hover:-right-12 z-10 w-14 h-14 rounded-full group-hover:scale-150 group-hover:opacity-50 duration-500 bg-sky-600"></div>
                                <p className="z-10">{t("Upload Now")}</p>
                            </button>
                        }
                    </form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="bg-slate-700 outline-none" onClick={() => {
                    props.onHide();
                }}>{t("Cancel")}</Button>
            </Modal.Footer>
            <OverLayLoading loading={editImageLoading} loadingtext={t("Updating image...")} />
        </Modal>
    );
}

export default UpdateUserImageForm;
