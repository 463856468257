// import { useDispatch, useSelector } from "react-redux";
// import "./contact.css";
// import { useForm } from "react-hook-form";
// import { postMessageFunc } from "../../../../store/slices/contact/PostMessageSlicer";
// import OverLayLoading from "./SendingMessageLoading";
// import { useContext, useState } from "react";
// import ContextApi from "../../../../store/slices/Context";
// import ContactBox from "./contact-box/ContactBox";
// import { Link } from "react-router-dom";
// import PageHeader from "../../../../component/ui/PageHeader";
// import contactImg from "../../../../images/courses-header.jpg"
// import { toast } from "react-toastify";
// import { useTranslation } from "react-i18next";
// export default function ContactForm() {
//     const {t} = useTranslation();
//     const {register , handleSubmit , reset , formState : {errors}} = useForm();
//     const dispatch = useDispatch();
//     const contactApi = `${process.env.REACT_APP_PUBLIC_API}/api/contacts`
//     const {loading:sendingLoading} = useSelector(state => state.postMessageSlicer);
//     const contextApi = useContext(ContextApi)
//     const email = contextApi.contact_email;
//     const phone = contextApi.contact_phone;
//     const address = contextApi.contact_address;
//     const [checkTerms , setCheckTerms] = useState(false);
//     const handleSubmitMessage = (data) => {
//       if(data) {
//         dispatch(postMessageFunc({api : contactApi , data : data}))
//         .then(result => {
//           if(result.payload.success === true){
//             reset()
//             toast.success(result?.payload?.message, {
//               position: "top-right",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//             });
//             setCheckTerms(false);
//           }
//         })
//       }
//     }
//     return (
//       <>
//         <PageHeader title={"تواصل معنا"} headerImg={contactImg} />
//         <div className="page-content relative w-full min-h-[100vh] pt-[50px] bg-[#f4f4f4]">
//           <div className="page-container my-3 relative w-[90%] mx-auto  row flex justify-between">
//             <div className="relative col-sm-12 col-md-4">
//               <ContactBox
//                 icon={<i className="fa-regular fa-envelope m-1 text-[#1c3d55] text-[35px]"></i>}
//                 title={t("البريد الالكتروني")}
//                 content={<Link to={`mailto:${email}`}>{email}</Link>}
//                 />
//               <ContactBox
//                 icon={<i className="fa-solid fa-phone-volume m-1 text-[#1c3d55] text-[35px]"></i>}
//                 title={t("موبايل")}
//                 content={<Link to={`https://wa.me/${phone}`} target="_blank">{phone}</Link>}
//               />
//               <ContactBox
//                 icon={<i className="fa-solid fa-location-dot m-1 text-[#1c3d55] text-[35px]"></i>}
//                 title={t("عنوان")}
//                 content={address}
//               />
//             </div>
//             <div className="relative form-div col-sm-12 col-md-8 border py-2 bg-white">
//               <form
//                 id="new-message-form"
//                 className="relative  w-full m-0"
//                 noValidate="novalidate"
//                 onSubmit={handleSubmit(data=>{
//                   handleSubmitMessage(data)
//                 })}
//               >
//                 <div className="personal-details flex flex-col border-b border-slate-100 py-2">
//                   <div className="relative details-header flex items-center mb-4">
//                     <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-[#1c3d55] rounded-[50%] font-bold">1</span>
//                     <div className="relative flex flex-col">
//                       <h2>{t("بيانات شخصية")}</h2>
//                       <p className="text-sm mt-2">{t("قم بادخال البيانات الشخصية ")}.</p>
//                     </div>
//                   </div>
//                   <div className="relative row m-0 flex gap-2 justify-between">
//                     <div className="relative flex flex-col col-sm-12 col-md-5">
//                       {errors.name?.message &&
//                         <p className="text-red-600 text-sm mb-1">{t(errors.name?.message)}</p>
//                       }
//                       <input
//                         {...register("name" , {required : t("يجب كتابه الاسم ")})}
//                         type="text"
//                         placeholder={t("الاسم")}
//                         className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
//                         />
//                     </div>
//                     <div className="relative flex flex-col col-sm-12 col-md-5">
//                       {errors.email?.message &&
//                         <p className="text-red-600 text-sm mb-1">{t(errors.email?.message)}</p>
//                       }
//                       <input
//                         {...register("email" , {required : t("يجب كتابة البريد الالكتروني")})}
//                         type="email"
//                         placeholder={t("البريد الالكتروني")}
//                         className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
//                         />
//                     </div>
//                     <div className="relative flex flex-col col-sm-12">
//                       {errors.phone?.message &&
//                         <p className="text-red-600 text-sm mb-1">{t(errors.phone?.message)}</p>
//                       }
//                       <input
//                         {...register("phone" , {required : t("رقم الموبايل مطلوب")})}
//                         type="text"
//                         placeholder={t("رقم الموبايل")}
//                         className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 <div className="contact-details flex flex-col border-b border-slate-100 py-2">
//                   <div className="relative details-header flex items-center mb-4">
//                     <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-[#1c3d55] rounded-[50%] font-bold">2</span>
//                     <div className="relative flex flex-col">
//                       <h2>{t("الموضوع")}</h2>
//                       <p className="text-sm mt-2">{t("قم بادخال موضوع الرسالة")}</p>
//                     </div>
//                   </div>
//                   <div className="relative row m-0 flex gap-2 justify-between">
//                     {errors.subject?.message &&
//                       <p className="text-red-600 text-sm mb-1">{t(errors.subject?.message)}</p>
//                     }
//                     <input
//                       {...register("subject" , {required : t("يجب كتابه عنوان للرساله الخاصه بك")})}
//                       type="text"
//                       placeholder={t("موضوع")}
//                       className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
//                     />
//                   </div>
//                 </div>
//                 <div className="contact-details flex flex-col border-b border-slate-100 py-2">
//                   <div className="relative details-header flex items-center mb-4">
//                     <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-[#1c3d55] rounded-[50%] font-bold">3</span>
//                     <div className="relative flex flex-col">
//                       <h2>{t("محتوي الرسالة")}</h2>
//                       <p className="text-sm mt-2">{t("قم بادخال تفاصيل الرسالة")}</p>
//                     </div>
//                   </div>
//                   <div className="relative row m-0 flex gap-2 justify-between">
//                     {errors.message?.message &&
//                       <p className="text-red-600 text-sm mb-1">{t(errors.message?.message)}</p>
//                     }
//                     <textarea
//                     {...register("message" , {required : t("يجب كتابه محتوي للرساله الخاصه بك")})}
//                     className="shadow-none py-2"
//                       rows={6}
//                     >
//                     </textarea>
//                   </div>
//                 </div>
//                 <div className="flex items-center">
//                   <button type="submit" form="new-message-form" class={`border text-gray-50  duration-300 relative group cursor-pointer   overflow-hidden h-16 w-48 rounded-md bg-neutral-800 p-2  font-extrabold hover:bg-sky-700 ${!checkTerms ? " pointer-events-none opacity-70" : ""}`}>
//                     {
//                       sendingLoading
//                       ?
//                       t("جاري الارسال")
//                       :
//                       <>
//                       <div class="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-16 h-16 rounded-full group-hover:scale-150  duration-700 right-12 top-12 bg-[#2eaecb]"></div>
//                       <div class="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-12 h-12 rounded-full group-hover:scale-150  duration-700 right-20 -top-6 bg-[#2375cf]"></div>
//                       <div class="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-8 h-8   rounded-full group-hover:scale-150  duration-700 right-32 top-6 bg-[#448fd1]"></div>
//                       <div class="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-4 h-4   rounded-full group-hover:scale-150  duration-700 right-2 top-12 bg-[#0097A7]"></div>
//                       <p class="z-10 absolute bottom-2 left-2">{t("ارسال")}</p>
//                       </>
//                     }
//                   </button>
//                   <div className="relative mx-2 flex items-center">
//                     <input 
//                     type="checkbox" 
//                     className="cursor-pointer" 
//                     id="terms-and-conditions"
//                     onChange={()=> setCheckTerms(!checkTerms)}
//                     />
//                     <label htmlFor="terms-and-conditions" className="text-sm mx-1 mb-1 cursor-pointer">{t("اوافق علي الشروط والاحكام")}</label>
//                   </div>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//         <OverLayLoading loadingText={"Sending Message.."} loading={sendingLoading} />
//       </>
//     )
// };
import { useDispatch, useSelector } from "react-redux";
import "./contact.css";
import { useForm } from "react-hook-form";
import { postMessageFunc } from "../../../../store/slices/contact/PostMessageSlicer";
import OverLayLoading from "./SendingMessageLoading";
import { useContext, useState } from "react";
import ContextApi from "../../../../store/slices/Context";
import ContactBox from "./contact-box/ContactBox";
import { Link } from "react-router-dom";
import PageHeader from "../../../../component/ui/PageHeader";
import contactImg from "../../../../images/courses-header.webp"
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function ContactForm() {
    const {t} = useTranslation();
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const dispatch = useDispatch();
    const contactApi = `${process.env.REACT_APP_PUBLIC_API}/api/contacts`
    const {loading:sendingLoading} = useSelector(state => state.postMessageSlicer);
    const contextApi = useContext(ContextApi)
    const email = contextApi.contact_email;
    const phone = contextApi.contact_phone;
    const address = contextApi.contact_address;
    const [checkTerms , setCheckTerms] = useState(false);
    const handleSubmitMessage = (data) => {
      if(data) {
        dispatch(postMessageFunc({api : contactApi , data : data}))
        .then(result => {
          if(result.payload.success === true){
            reset()
            toast.success(result?.payload?.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setCheckTerms(false);
          }
        })
      }
    }
    return (
      <>
        <PageHeader title={"Contact Us"} headerImg={contactImg} />
        <div className="page-content relative w-full min-h-[100vh] pt-[50px] bg-[#f4f4f4]">
          <div className="page-container my-3 relative w-[90%] mx-auto  row flex justify-between">
            <div className="relative col-sm-12 col-md-4">
              <ContactBox
                icon={<i className="fa-regular fa-envelope m-1 text-primary text-[35px]"></i>}
                title={t("Email")}
                content={<Link to={`mailto:${email}`}>{email}</Link>}
                />
              <ContactBox
                icon={<i className="fa-solid fa-phone-volume m-1 text-primary text-[35px]"></i>}
                title={t("Mobile")}
                content={<Link to={`https://wa.me/${phone}`} target="_blank">{phone}</Link>}
              />
              <ContactBox
                icon={<i className="fa-solid fa-location-dot m-1 text-primary text-[35px]"></i>}
                title={t("Address")}
                content={address}
              />
            </div>
            <div className="relative form-div col-sm-12 col-md-8 border py-2 bg-white">
              <form
                id="new-message-form"
                className="relative  w-full m-0"
                noValidate="novalidate"
                onSubmit={handleSubmit(data=>{
                  handleSubmitMessage(data)
                })}
              >
                <div className="personal-details flex flex-col border-b border-slate-100 py-2">
                  <div className="relative details-header flex items-center mb-4">
                    <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-primary rounded-[50%] font-bold">1</span>
                    <div className="relative flex flex-col">
                      <h2>{t("Personal Details")}</h2>
                      <p className="text-sm mt-2">{t("Please enter your personal details.")}</p>
                    </div>
                  </div>
                  <div className="relative row m-0 flex gap-2 justify-between">
                    <div className="relative flex flex-col col-sm-12 col-md-5">
                      {errors.name?.message &&
                        <p className="text-red-600 text-sm mb-1">{t(errors.name?.message)}</p>
                      }
                      <input
                        {...register("name" , {required : t("Name is required")})}
                        type="text"
                        placeholder={t("Name")}
                        className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
                        />
                    </div>
                    <div className="relative flex flex-col col-sm-12 col-md-5">
                      {errors.email?.message &&
                        <p className="text-red-600 text-sm mb-1">{t(errors.email?.message)}</p>
                      }
                      <input
                        {...register("email" , {required : t("Email is required")})}
                        type="email"
                        placeholder={t("Email")}
                        className={"col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"}
                        />
                    </div>
                    <div className="relative flex flex-col col-sm-12">
                      {errors.phone?.message &&
                        <p className="text-red-600 text-sm mb-1">{t(errors.phone?.message)}</p>
                      }
                      <input
                        {...register("phone" , {required : t("Phone number is required")})}
                        type="text"
                        placeholder={t("Phone Number")}
                        className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
                      />
                    </div>
                  </div>
                </div>
                <div className="contact-details flex flex-col border-b border-slate-100 py-2">
                  <div className="relative details-header flex items-center mb-4">
                    <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-primary rounded-[50%] font-bold">2</span>
                    <div className="relative flex flex-col">
                      <h2>{t("Subject")}</h2>
                      <p className="text-sm mt-2">{t("Please enter the subject of the message")}</p>
                    </div>
                  </div>
                  <div className="relative row m-0 flex gap-2 justify-between">
                    {errors.subject?.message &&
                      <p className="text-red-600 text-sm mb-1">{t(errors.subject?.message)}</p>
                    }
                    <input
                      {...register("subject" , {required : t("Subject is required")})}
                      type="text"
                      placeholder={t("Subject")}
                      className="col-sm-12 shadow-none relative placeholder:text-[13px] placeholder:opacity-80"
                    />
                  </div>
                </div>
                <div className="contact-details flex flex-col border-b border-slate-100 py-2">
                  <div className="relative details-header flex items-center mb-4">
                    <span className="w-[50px] h-[50px] flex justify-center items-center mx-2 text-white bg-primary rounded-[50%] font-bold">3</span>
                    <div className="relative flex flex-col">
                      <h2>{t("Message Content")}</h2>
                      <p className="text-sm mt-2">{t("Please enter the details of your message")}</p>
                    </div>
                  </div>
                  <div className="relative row m-0 flex gap-2 justify-between">
                    {errors.message?.message &&
                      <p className="text-red-600 text-sm mb-1">{t(errors.message?.message)}</p>
                    }
                    <textarea
                    {...register("message" , {required : t("Message content is required")})}
                    className="shadow-none py-2"
                      rows={6}
                    >
                    </textarea>
                  </div>
                </div>
                <div className="flex items-center">
                  <button type="submit" form="new-message-form" className={`border text-gray-50 duration-300 relative group cursor-pointer overflow-hidden h-16 w-48 rounded-md bg-neutral-800 p-2 font-extrabold hover:bg-sky-700 ${!checkTerms ? "pointer-events-none opacity-70" : ""}`}>
                    {
                      sendingLoading
                      ?
                      t("Sending")
                      :
                      <>
                        <div className="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-16 h-16 rounded-full group-hover:scale-150 duration-700 right-12 top-12 bg-[#2eaecb]"></div>
                        <div className="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-12 h-12 rounded-full group-hover:scale-150 duration-700 right-20 -top-6 bg-[#2375cf]"></div>
                        <div className="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-8 h-8 rounded-full group-hover:scale-150 duration-700 right-32 top-6 bg-[#448fd1]"></div>
                        <div className="absolute group-hover:-top-1 group-hover:-right-2 z-10 w-4 h-4 rounded-full group-hover:scale-150 duration-700 right-2 top-12 bg-[#0097A7]"></div>
                        <p className="z-10 absolute bottom-2 left-2">{t("Send")}</p>
                      </>
                    }
                  </button>
                  <div className="relative mx-2 flex items-center">
                    <input 
                    type="checkbox" 
                    className="cursor-pointer" 
                    id="terms-and-conditions"
                    onChange={()=> setCheckTerms(!checkTerms)}
                    />
                    <label htmlFor="terms-and-conditions" className="text-sm mx-1 mb-1 cursor-pointer">{t("I agree to the terms and conditions")}</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <OverLayLoading loadingText={"Sending Message.."} loading={sendingLoading} />
      </>
    )
};
