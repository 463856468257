import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const getSingleTeacherFunc = createAsyncThunk('teachers/get/single/func',
async (api)=> {
    try {
        const response = await axios.get(api)
        checkErrorsFunctions(response , response.data.errors);
        return response.data.data.teacher
    }
    catch(error) {
        checkErrors(error.response , error.message);
    }
}
);


const getSingleTeacherSlicer =  createSlice({
    name:'teachers/get/single',
    initialState :{
        loading:false,
        data:null,
        error:null
    },
    extraReducers: (builder)=>{
    builder.addCase(getSingleTeacherFunc.pending,(state)=>{
        state.loading = true;
        state.data = null;
        state.error = null;
        
    });
    builder.addCase(getSingleTeacherFunc.fulfilled,(state,action)=>{
        state.loading = false;
        state.data = action.payload;
        state.error = null;
    });
    builder.addCase(getSingleTeacherFunc.rejected ,(state,action)=>{
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
    })
    }

});


export default getSingleTeacherSlicer.reducer;
