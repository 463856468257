import { useEffect } from "react";
import MobileNavbar from "../../component/Nav/MobileNavbar";
import Navbar from "../../component/Nav/Navbar";
import Offcanvan from "../../component/Nav/Offcanvan";
import Footer from "../../component/ui/Footer";
import PageHeader from "../../component/ui/PageHeader";
import ScrollUp from "../../component/ui/ScrollUp";
import SearchBar from "../../component/ui/SearchBar";
import Wrappers from "../../component/ui/Wrappers";
import AboutSection from "../About/component/AboutSection";
import PersonInformation from "./component/PersonInformation";
import './style/style.css'

export default function Profile() {
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  return (
    <>
    
        {/* <PageHeader title="Profile" /> */}
        <PersonInformation />
       
    </>
  );
}
