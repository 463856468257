import { toast } from "react-toastify";
export function checkErrors (response , errorMessage) {
    console.log(response)
    console.log(errorMessage)
    if(response) {
        if(response.data && response.data.errors && (response.data.errors.length >= 1 || Object.keys(response.data.errors).length >=1)) {
            const errorsObject = response.data.errors;
            Object.keys(errorsObject).forEach((key) => {
                errorsObject[key].forEach((errorMessage) => {
                  toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
              });
        }else {
            if(response.data && response.data.message) {
                if(response.data.status_code === 401 || response.data.message === "Unauthenticated."){
                    // Swal.fire({
                    //     title: "Error!",
                    //     text: response.data.message,
                    //     icon: 'error',
                    //     confirmButtonText: 'OK',
                    //     customClass : {
                    //         popup : "z-index-class"
                    //     }
                    // });
                    if (window.location.pathname !== "/login") {
                        window.localStorage.clear();
                        window.location.pathname = "/login";
                    }else {
                        toast.error(response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        window.localStorage.clear();
                    }
                }else {
                    // Swal.fire({
                    //     title: "Error!",
                    //     text: response.data.message,
                    //     icon: 'error',
                    //     confirmButtonText: 'OK',
                    //     customClass : {
                    //             popup : "z-index-class"
                    //         }
                    // });
                    toast.error(response.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }
        }
    }else {
        // Swal.fire({
        //     title: "Error!",
        //     text: errorMessage,
        //     icon: 'error',
        //     confirmButtonText: 'OK',
        //     customClass : {
        //         popup : "z-index-class"
        //     }
        // });
        toast.error(errorMessage, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}