// import { useContext, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { loginUser } from "../../../store/slices/userSlicer";
// import { Link, useNavigate } from "react-router-dom";
// import logo from "../../../images/academy-logo.png";
// import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
// import { useForm } from "react-hook-form";
// import "../styles/login-custome-style.css";
// import { getProfileData } from "../../../store/slices/users/profileSlicer";
// import loginBg from "../../../images/login-bg.jpg"
// import ContextApi from "../../../store/slices/Context";
// // import {TextField} from '@mui/material';

// export default function LoginFrom() {
//   const [showSendingEmailModal, setShowSendingEmailModal] = useState();
//   const navigator = useNavigate();
//   const {register , formState : {errors} , reset , handleSubmit} = useForm()
//   const [showPass , setShowPass] = useState(false);
//   const dispatch = useDispatch();
//   const { loading, error } = useSelector((state) => state.user);
//   const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
//   const contextApi = useContext(ContextApi)
//   const siteLogo = contextApi?.site_logo;
//   const getProfile = async () => {
//     try {
//       dispatch(getProfileData(profileApi))
//       .then(result => {
//         if(result.payload && result.payload.invoices) {
//           if (result.payload.invoices && result.payload.invoices.length >= 1) {
//             const invoices = result.payload.invoices;
//             window.localStorage.setItem("studentInvoices" , JSON.stringify(invoices))
//           }
//         }
//       })
//     } catch (error) {
//       console.log(error);
//     }
//   };
//   const handleSubmitData = (data) => {
//     dispatch(loginUser(data)).then(result =>{
//       if(result.payload?.data) {
//         if(result.payload.data.student) {
//           if(!result.payload.data.student.email_verified_at) {
//             navigator('/verify-email');
//           }else {
//             if (result.payload.success === true) {
//               navigator('/');
//               window.location.reload();
//               getProfile();
//               reset();
//             }
//           }
//         }
//       }
//     });
//   };

//   return (
//     <>
//         <div className="page-content relative w-full h-[100vh]"
//             style={{backgroundImage: `url(${loginBg})` , backgroundPosition: "center center" , backgroundRepeat: "no-repeat" , backgroundSize: "cover"}}
//         >
//             {/* <img src={img} alt="img" className="absolute top-0 left-0 w-full h-full" /> */}
//             <div className="page-container row relative w-[90%] flex justify-center items-center h-full mx-auto py-4 ">
//                 <div className="form-div relative flex flex-col justify-center items-center w-full md:w-[400px] rounded-lg py-3 bg-[#f7f7f7] " style={{boxShadow: "2px 2px 85px #d9d9d9"}}>
//                     <i className="fa-solid fa-arrow-left absolute top-[15px] left-[15px] text-blue-900 cursor-pointer transition-all duration-500 hover:translate-x-[-4px]"
//                         onClick={() => window.history.back()}
//                     ></i>
//                     <Link to="/">
//                         <img src={siteLogo} alt="logo" className="w-[150px]" />
//                     </Link>
//                     <p className="text-sm">ليس لديك حساب؟ <Link to={"/register"} className="text-primary">انشاء حساب</Link></p>
//                     <div className="register-form-content col-sm-12">
//                             {
//                             error 
//                             && 
//                             (
//                               <p className="mb-1 login-error text-red-700 text-sm text-center bg-[#ef444485] border border-red-700 rounded-md py-2 px-3">{error}</p>
//                             )
//                             }
//                             <form onSubmit={handleSubmit((data) => {
//                               handleSubmitData(data);
//                             })}>
//                               <div className="form-groub relative mb-1 w-full">
//                                 <label className="mb-2 text-sm">البريد الالكتروني</label>
//                                 {errors.email?.message &&
//                                 <p className="text-red-600 text-sm mb-1">{errors.email?.message}</p>
//                                 }
//                                 <div className="relative flex flex-col col-sm-12">
//                                     <input
//                                         {...register("email" , {required : "البريد الالكتروني مطلوب"})}
//                                         className="w-full col-sm-12 indent-[1px] text-sm flex rounded-lg h-[50px] bg-transparent border placeholder:opacity-40 shadow-none"
//                                         type="email"
//                                     />
//                                 </div>
//                               </div>
//                               <div className="form-groub relative mb-1 w-full">
//                                 <label className="mb-2 text-sm">كلمة المرور</label>
//                                 {errors.password?.message &&
//                                 <p className="text-red-600 text-sm mb-1">{errors.password?.message}</p>
//                                 }
//                                 <div className="relative flex flex-col col-sm-12">
//                                     <input
//                                         {...register("password" , {required : "كلمه المرور مطلوبة"})}
//                                         className="w-full col-sm-12 indent-[1px] text-sm flex h-[50px] bg-transparent border placeholder:opacity-40 shadow-none"
//                                         type={`${showPass ? "text" : "password"}`}
//                                     />
//                                     {
//                                       showPass
//                                       ?
//                                       <i className="fa-solid fa-eye-slash cursor-pointer absolute top-[50%] translate-y-[-50%] left-[10px]"
//                                       onClick={() => setShowPass(!showPass)}
//                                       ></i>
//                                       :
//                                       <i class="fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] left-[10px]"
//                                       onClick={() => setShowPass(!showPass)}
//                                       ></i>
//                                     }
//                                 </div>
//                               </div>
//                               <div className="relative mb-2">
//                                 <Link
//                                   className="text-sm my-1 inline-block"
//                                   onClick={() => setShowSendingEmailModal(true)}
//                                 >نسيت كلمة المرور ؟</Link>
//                               </div>
//                               <div className="tutor-form-row">
//                                 <div className="tutor-form-col-12">
//                                   <div className="tutor-form-group tutor-reg-form-btn-wrap">
//                                     <button
//                                       type="submit"
//                                       name="tutor_register_student_btn"
//                                       className="btn col-sm-12 bg-primary text-white transition-all duration-500 hover:translate-y-[-3px] hover:bg-slate-800"
//                                     >
//                                       {loading
//                                         ? "جاري التحميل ..."
//                                         : 
//                                         <>
//                                         دخول الان
//                                         <i className="fa-solid fa-right-to-bracket transition-all duration-500 mr-1 rotate-[180deg] text-white"></i>
//                                         </>
//                                         }
//                                     </button>
//                                   </div>
//                                 </div>
//                               </div>
//                             </form>
//                           </div>
//                 </div>
//             </div>
//         </div>
//     <SendEmailModal
//       show = {showSendingEmailModal}
//       onHide = {() => setShowSendingEmailModal(false)}
//     />
//     </>
// )
// }
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/slices/userSlicer";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../images/academy-logo.png";
import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
import { useForm } from "react-hook-form";
import "../styles/login-custome-style.css";
import { getProfileData } from "../../../store/slices/users/profileSlicer";
import loginBg from "../../../images/login-bg.jpg"
import ContextApi from "../../../store/slices/Context";
import { useTranslation } from "react-i18next";

export default function LoginFrom() {
  const {t} = useTranslation();
  const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const [showSendingEmailModal, setShowSendingEmailModal] = useState();
  const navigator = useNavigate();
  const {register , formState : {errors} , reset , handleSubmit} = useForm()
  const [showPass , setShowPass] = useState(false);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
  const contextApi = useContext(ContextApi)
  const siteLogo = contextApi?.site_logo;
  const getProfile = async () => {
    try {
      dispatch(getProfileData(profileApi))
      .then(result => {
        if(result.payload && result.payload.invoices) {
          if (result.payload.invoices && result.payload.invoices.length >= 1) {
            const invoices = result.payload.invoices;
            window.localStorage.setItem("studentInvoices" , JSON.stringify(invoices))
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitData = (data) => {
    dispatch(loginUser(data)).then(result =>{
      if(result.payload?.data) {
        if(result.payload.data.student) {
          if(!result.payload.data.student.email_verified_at) {
            navigator('/verify-email');
          }else {
            if (result.payload.success === true) {
              navigator('/');
              window.location.reload();
              getProfile();
              reset();
            }
          }
        }
      }
    });
  };

  return (
    <>
        <div className="page-content relative w-full h-[100vh]"
            style={{backgroundImage: `url(${loginBg})` , backgroundPosition: "center center" , backgroundRepeat: "no-repeat" , backgroundSize: "cover"}}
        >
            <div className="page-container row relative w-[90%] flex justify-center items-center h-full mx-auto py-4 ">
                <div className="form-div relative flex flex-col justify-center items-center w-full md:w-[400px] rounded-lg py-3 bg-[#f7f7f7] " style={{boxShadow: "2px 2px 85px #d9d9d9"}}>
                    <i className="fa-solid fa-arrow-left absolute top-[15px] left-[15px] text-blue-900 cursor-pointer transition-all duration-500 hover:translate-x-[-4px]"
                        onClick={() => navigator('/')}
                    ></i>
                    <Link to="/">
                        <img src={siteLogo} alt="logo" className="w-[150px]" />
                    </Link>
                    <p className="text-sm">{t("You don't have an account?")} <Link to={"/register"} className="text-primary">{t("Create an account")}</Link></p>
                    <div className="register-form-content col-sm-12">
                            {
                            error 
                            && 
                            (
                              <p className="mb-1 login-error text-red-700 text-sm text-center bg-[#ef444485] border border-red-700 rounded-md py-2 px-3">{error}</p>
                            )
                            }
                            <form onSubmit={handleSubmit((data) => {
                              handleSubmitData(data);
                            })}>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm">{t("Email")}</label>
                                {errors.email?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.email?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("email" , {required : t("Email is required")})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex rounded-lg h-[50px] bg-transparent border placeholder:opacity-40 shadow-none"
                                        type="email"
                                    />
                                </div>
                              </div>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm">{t("Password")}</label>
                                {errors.password?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.password?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("password" , {required : t("Password is required")})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex h-[50px] bg-transparent border placeholder:opacity-40 shadow-none"
                                        type={`${showPass ? "text" : "password"}`}
                                    />
                                    {
                                      showPass
                                      ?
                                      <i className={`fa-solid fa-eye-slash cursor-pointer absolute top-[50%] translate-y-[-50%] ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"} `}
                                      onClick={() => setShowPass(!showPass)}
                                      ></i>
                                      :
                                      <i className={`fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"}`}
                                      onClick={() => setShowPass(!showPass)}
                                      ></i>
                                    }
                                </div>
                              </div>
                              <div className="relative mb-2">
                                <Link
                                  className="text-sm my-1 inline-block"
                                  onClick={() => setShowSendingEmailModal(true)}
                                >{t("Forgot your password?")}</Link>
                              </div>
                              <div className="tutor-form-row">
                                <div className="tutor-form-col-12">
                                  <div className="tutor-form-group tutor-reg-form-btn-wrap">
                                    <button
                                      type="submit"
                                      name="tutor_register_student_btn"
                                      className="btn col-sm-12 bg-primary text-white transition-all duration-500 hover:translate-y-[-3px] hover:bg-slate-800"
                                    >
                                      {loading
                                        ? t("Loading...")
                                        : 
                                        <>
                                        <i className="fa-solid fa-right-to-bracket transition-all duration-500 mr-1 rotate-[180deg] text-white"></i>
                                        {t("Login now")}
                                        </>
                                        }
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                </div>
            </div>
        </div>
    <SendEmailModal
      show = {showSendingEmailModal}
      onHide = {() => setShowSendingEmailModal(false)}
    />
    </>
  )
}


