import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postReviewFunc } from "../../../../store/slices/reviews/PostReviewSlicer";
import { toast } from "react-toastify";
import studentImg from "../../../../images/graduated.png";
import { useTranslation } from "react-i18next";

function RatingStars({ handleSetRatingCount }) {
  const { t } = useTranslation();
  const [rating, setRating] = useState(null);
  const [ratingText, setRatingText] = useState("");

  useEffect(() => {
    handleSetRatingCount(rating);
    const switchRatingCount = () => {
      if (rating) {
        switch (rating) {
          case 1:
            setRatingText(t("One star"));
            break;
          case 2:
            setRatingText(t("Two stars"));
            break;
          case 3:
          case 4:
          case 5:
            setRatingText(<>{rating} {t("stars")}</>);
            break;
          default:
            setRatingText(t("One star"));
        }
      }
    };
    switchRatingCount();
  }, [rating]);

  return (
    <>
      <div className="d-flex align-items-center justify-content-center w-100">
        {[...Array(5)].map((item, index) => {
          return (
            <span
              key={index}
              className="mx-1"
              style={{ fontSize: "20px", cursor: "pointer", color: index + 1 <= rating ? "orange" : "", transition: "all .3s" }}
              onMouseOver={() => {
                setRating(index + 1);
              }}
            >
              &#9733;
            </span>
          );
        })}
      </div>
      {rating && (
        <span className="text-sm flex">
          <p className="">{t("Rating")}:</p> {ratingText}
        </span>
      )}
    </>
  );
}

export default function ReviewForm({ course_id }) {
  const { t } = useTranslation();
  const reviewsApi = `${process.env.REACT_APP_PUBLIC_API}/api/reviews`;
  const { loading: sendFeebackLoading } = useSelector((state) => state.postReviewSlicer);
  const [message, setMessage] = useState("");
  const [ratingCount, setRatingCount] = useState(null);
  const dispatch = useDispatch();
  const { loading: profileLoading, data: profileData, error: profileError } = useSelector((s) => s.profileSlicer);
  const [studentData, setStudentData] = useState({});

  useEffect(() => {
    if (profileData) {
      setStudentData(profileData.student);
    }
  }, [profileData]);

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (ratingCount) {
      const ratingForm = new FormData();
      ratingForm.append("rating", ratingCount);
      ratingForm.append("comment", message);
      ratingForm.append("course_id", course_id);
      try {
        dispatch(postReviewFunc({ url: reviewsApi, data: ratingForm }))
          .then((result) => {
            if (result?.payload?.success) {
              toast.success(result.payload.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setMessage("");
            }
          });
      } catch (error) {
        toast.error(t("Error submitting review"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleSetRatingCountFunc = (rating) => {
    setRatingCount(rating);
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmitData(e)}>
        <div className="relative flex flex-col my-3">
          <div className="relative row m-0 p-0">
            <div className="user-img col-sm-2 w-fit flex justify-center items-center">
              <img loading="lazy" src={studentData?.image_url !== null ? studentData?.image_url : studentImg} className="w-[50px] mx-auto" alt="user img" />
            </div>
            <textarea
              rows={1}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="py-3 mx-1 text-sm border-t-0 border-r-0 border-l-0 border-b border-slate-700 shadow-none rounded-none col-sm-10"
              placeholder={t("Add a comment...")}
            ></textarea>
          </div>
          <div className="rating-div flex justify-end items-end">
            <div className="relative">
              <RatingStars handleSetRatingCount={handleSetRatingCountFunc} />
            </div>
            <Button variant="contained" type="submit" className={`relative w-fit bg-primary ${(sendFeebackLoading || message === '') ? "pointer-events-none opacity-50" : ""} transition-all duration-500`}>
              {sendFeebackLoading ? t("Loading...") : t("Send")}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
}
