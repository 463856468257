// import "./teacher-card.css";
// import { useTranslation } from "react-i18next"
// import { Link } from "react-router-dom";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// export default function TeacherCard({teacher , id ,  teacherImg , name , baio , coursesCount , inSwiper}) {
//     const {t} = useTranslation();
//     return (
//         <>
//             <div className={`${inSwiper ? "col-sm-12" : "col-sm-12 col-md-3"} teacher-card relative min-h-[350px] px-3 overflow-hidden rounded-xl  flex flex-col justify-center items-center transition-all duration-300  hover:translate-y-[-4px] group after:opacity-0 translate-y-[10px] after:transition-all after:duration-500 hover:after:opacity-100 hover:after:translate-y-0`} style={{boxShadow : "1px 1px 19px #e5e5e5"}}>
//              <img src={teacherImg ? teacherImg : "sca"} alt="teacher" className="absolute w-full h-full opacity-100" />
//                 {/* <div className="relative image-div w-[100px] h-[100px] group">
//                     <div className="absolute top-0 left-0 transition-all duration-500 group-hover:rotate-[0] group-hover:opacity-0 rounded-lg border-3 border-slate-700 rotate-[45deg] w-[100px] h-[100px]"></div>
//                     <div className="absolute top-0 left-0 rounded-lg overflow-hidden  w-[100px] h-[100px]">
//                         <img src={teacherImg ? teacherImg : "sca"} alt="teacher" className="absolute w-full h-full opacity-100" />
//                     </div>
//                 </div> */}
//                 <Link to={`/instructor-profile/${id}`} className="relative transition-all duration-500 opacity-0 translate-y-[10px] group-hover:opacity-100 group-hover:translate-y-0 z-20">
//                     <h2 className="relative my-[30px] font-bold text-white">{name}</h2>
//                 </Link>
//                 {/* <div className="reative flex flex-col justify-center items-center my-3">
//                     <p className="text-center">{baio?.length >= 100 ? `${baio?.slice(0,70)}....` : baio}</p>
//                 </div> */}
//                 {/* {
//                     coursesCount >= 1
//                     ?
//                     <div className="card-footer relative w-full px-2 py-[20px] border-t border-slate-800  bg-transparent">
//                         {t("Courses")} : 
//                         <p className="px-2 inline-flex mx-2 rounded-[50%] bg-blue-500 group-hover:bg-blue-600 text-white transition-all duration-300 border border-white w-fit">{coursesCount}</p>
//                     </div>
//                     :
//                     ""
//                 } */}
//                 <Link to={`/instructor-profile/${id}`} className="absolute top-0 left-0 w-full h-full z-20"></Link>
//             </div>
        
//         </>
//     )
// }
export default function TeacherCard({teacher , id ,  teacherImg , name , baio , coursesCount , inSwiper}) {
    const {t} = useTranslation();
    return (
        <>
            <div className={`relative max-h-[300px] p-2 rounded-md ${inSwiper ? "col-sm-12 w-full" : "col-sm-12 col-md-3"}`} style={{boxShadow : "1px 1px 19px #e5e5e5"}}>
                <div className="card-content relative flex flex-col justify-center items-center py-3">
                    <div className="img-div relative w-[100px] h-[100px] rounded-[50%] overflow-hidden border-2 border-primary">
                        <img src={teacherImg ? teacherImg : "sca"} alt="teacher" className="absolute w-full h-full opacity-100" />
                    </div>
                    <h3 className="my-2">{name}</h3>
                    <p className="text-sm my-2">{baio?.length >= 20 ? `${baio.slice(0,20)}...` : baio}</p>
                    <div className='relative flex justify-center items-center'>
                        <LibraryBooksIcon className='text-[17px]'/>
                        <span className='relative h-[20px] w-[1px] bg-slate-600 mx-1'></span>
                        <h2 className="relative inline-flex">
                            {t("Courses")}: {coursesCount}</h2>
                    </div>
                </div>
                <Link to={`/instructor-profile/${id}`} className="absolute top-0 left-0 w-full h-full z-20"></Link>
            </div>
        </>
    )
}