import { useState } from "react";

export default function CardExamMCQ({ numofcoustions,qunamber,question }) {

  const [answer, setAnswer] = useState("");
console.log(answer);

  return (
    <>
      <div className="tutor-card-exam">
        <div className="card-exam-title">
          <span className="question-number">
            <strong>السوال {qunamber+1} من {numofcoustions}</strong>
          </span>
          <span className="question-degree">
            <em>
              الدرجة : <strong>{question.grade}</strong>
            </em>
          </span>
        </div>
        <div className="card-exam-body">
          <div className="card-exam-body-questions">
            <p id={`ques-${question.id}`}>{question.title}</p>
          </div>
          <div className="card-exam-body-answers">
            <form
              className="comment-one__form contact-form-validated"
              noValidate="novalidate"
            >
              <div className="col-xl-12 col-lg-12">
                <div className="comment-form__input-box">
                  {question.options &&
                    question.options.map((option, index) => (
                      <div className="form-group form-check" key={index}>
                        <input
                          type="radio"
                          className="form-check-input"
                          id={`ques-${question.id}${index}-exampleCheck1`}
                          name={`ques-${question.id}`}
                          onChange={(e) => {
                            setAnswer(index);
                          }}
                          
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`ques-${question.id}${index}-exampleCheck1`}
                        >
                          {option}{" "}
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
