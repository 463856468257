import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../checkErrorsFunction";
import { checkErrors } from "../../../global/checkResponseError";

export const getProfileData = createAsyncThunk("user/profile", async (url) => {
  try {
    const response = await axios.get(url);
      // checkErrorsFunctions(response , response.data.errors);
      return response.data.data;
  } catch (error) {
    checkErrors(error.response , error.message)
    return error;
  }
});

const profileSlicer = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    data: [],
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getProfileData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getProfileData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default profileSlicer.reducer;
