import { useParams } from "react-router-dom";
import Btn from "../../../component/ui/Btn";
import CardExamMCQ from "./ui/CardExam";
import CardExamTF from "./ui/CardExamTF";
import CardExamUpload from "./ui/CardExamUpload";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuizeData } from "../../../store/slices/quizes/showQuizesSlicers";
import { Formik } from "formik";

export default function ExamSection() {
  const { quizeId } = useParams();
  const dispatch = useDispatch();
  const {
    loading: QuizeLoading,
    data: QuizeData,
    error: QuizeError,
  } = useSelector((s) => s.showQuizesSlicers);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [answersss, setAnswerss] = useState([]);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(answersss);
  };
  const handleSubmitt = (value, { resetForm }) => {
    console.log(value);
  };
  const sendAnswer = (data) => {
    setAnswerss(...data);
    console.log(answersss);
  };

  useEffect(() => {
    const getData = async () => {
      dispatch(
        getQuizeData(
          `${process.env.REACT_APP_PUBLIC_API}/api/quizzes/${quizeId}`
        )
      );
    };

    getData();
  }, []);

  useEffect(() => {
    QuizeData && setQuizQuestions(QuizeData);
  }, [QuizeData]);
  return (
    <>
      <div className="container">
        <div className="main-page-content row">
          {/* Main content */}
          <div className="content-page col-12">
            <div id="wp-content" className="wp-content">
              <div className="tutor-wrap sc-course tutor-page-wrap sc-layout-4 post-880 courses type-courses status-publish has-post-thumbnail hentry course-category-business post-single-content tutor-my-40">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                      {QuizeLoading ? (
                        <>
                          <div
                            classname="spinner-border text-primary"
                            role="status"
                          />
                        </>
                      ) : (
                        <>
                          {QuizeData &&
                            quizQuestions &&
                            quizQuestions.questions && (
                              <>
                                <h3 className="tutor-fs-3 tutor-fw-bold tutor-color-black  tutor-course-content-title">
                                  {quizQuestions.title}
                                </h3>
                                <p>
                                  عدد الاسئلة {quizQuestions.questions_count}
                                </p>
                                <div className="single-course-sidebar">
                                  <div className=" course-single-thumbnail course-single-card-exam">
                                 

                                    {quizQuestions.questions.map(
                                      (item, index) => (
                                        <>
                                          {item.type == 1 ? (
                                            <CardExamTF
                                              sendAnswer={sendAnswer}
                                              key={index}
                                              question={item}
                                              numofcoustions={
                                                quizQuestions.questions_coun
                                              }
                                              qunamber={index}
                                            />
                                          ) : (
                                            <>
                                              {item.type == 2 ? (
                                                <CardExamMCQ
                                                  key={index}
                                                  question={item}
                                                  numofcoustions={
                                                    quizQuestions.questions_coun
                                                  }
                                                  qunamber={index}
                                                />
                                              ) : (
                                                <CardExamUpload
                                                  key={index}
                                                  question={item}
                                                  numofcoustions={
                                                    quizQuestions.questions_coun
                                                  }
                                                  qunamber={index}
                                                />
                                              )}
                                            </>
                                          )}
                                        </>
                                      )
                                    )}
                                  </div>

                                  <div className="course-single-action tutor-my-40">
                                    {/* <div className="course-single-wishlist">
                          <a className="btn-gray-icon cart-required-login  ">
                            Next
                          </a>
                        </div> */}
                                    <button type="submit" className={`thm-btn`}>
                                      انهاء وارسال
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
